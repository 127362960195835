import { useRef, useState } from "react";
import { getData } from "../utils/FetchUtils";
import PopoverAutocomplete from "./Autocompletes/PopoverAutocomplete";

const AccountHolderAutocomplete = ({
    selectedOptions,
    setSelectedOptions,
    handleResetAutoCompletes,
    disableAccountHolderAutoComplete
}) => {
    const LIMIT = 10;
    const [searchQuery, setSearchQuery] = useState('');
    const [options, setOptions] = useState([]);
    const [textContent,setTextContent] = useState('Select account holder');
    const [placeholder,setPlaceholder] = useState('Search account holders');
    const [isLoading,setIsLoading] = useState(true);
    
    const offset = useRef(0);

    const callback = async () => {
        const query = `/api/earnings/account-holders?offset=${offset.current}&limit=${LIMIT}&filter=${searchQuery}`;
        const { items } = await getData(process.env.REACT_APP_SERVER_HOST + query);
        
        setIsLoading(false);
        setOptions(items);
        // Not sure why the concat, but in case something breaks..
        // setOptions(options.concat(items));
        offset.current += LIMIT;
    }

    return (
        <PopoverAutocomplete
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            options={options}
            setOptions={setOptions}
            offset={offset}
            callback={callback}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            textContent={textContent}
            placeholder = {placeholder}
            isLoading={isLoading}
            handleResetAutoCompletes={handleResetAutoCompletes}
            disableAccountHolderAutoComplete={disableAccountHolderAutoComplete}
        />
    )
};

export default AccountHolderAutocomplete;