import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { IconButton, ListItem, ListItemText, List, Collapse } from '@material-ui/core';
import { BrandPopover } from "../CoreComponents/BrandPopover";
import { AdditionalIsrcsUpcs } from "./AdditionalIsrcsUpcs";

const useStyles = makeStyles((theme) => ({
    listBulletsHeading: {
        color: theme.palette.primary.main,
        lineBreak: 'strict'
    },
    listBulletSpotifyIdContainer: {
        marginBottom: theme.spacing(3),
        lineBreak: 'anywhere'
    },
    listBulletHeadingContainer: {
        display: 'flex',
        lineBreak: 'anywhere'
    },
    listBulletAccountholderContainer: {
        display: 'flex'
    },
    listBulletsFontBoldProperties: {
        fontWeight: 'bold'
    },
    nested: {
        paddingLeft: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    }
}));

export function SongListItem({
    song, 
    onClick
}) {
    const styles = useStyles();
    const [open, setOpen] = useState(false);

    const onClickExpand = (e) => {
        e.stopPropagation();
        setOpen(!open);
    };

    const modifiedAccountHolders = !song.accountHolders || song.accountHolders.length === 0 ?
    "N/A"
    :
    song.accountHolders.length === 1 ?
    song.accountHolders[0]
        :
        `${song.accountHolders[0]}; +${song.accountHolders.length - 1}`

    return (
        <>
            <ListItem button onClick={() => onClick(song)}>
                <ListItemText>
                    {`${song.artistName ? song.artistName + ' - ' : ''}${song.name ? song.name + ' - ' : ''}${song.displayName}`}
                </ListItemText>
                {open ?
                    <IconButton onClick={onClickExpand}>
                        <ExpandLess />
                    </IconButton>
                    :
                    <IconButton onClick={onClickExpand}>
                        <ExpandMore />
                    </IconButton>
                }
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding dense={true}>
                    <ListItem className={styles.nested}>
                        <ListItemText>
                            <div className={styles.listBulletAccountholderContainer}>
                                <div className={styles.listBulletsHeading}>
                                    Account holder:&nbsp;
                                </div>
                                <BrandPopover 
                                    popoverData={song.accountHolders}
                                    popoverDataFormated={modifiedAccountHolders}
                                />
                            </div>
                        </ListItemText>
                        <ListItemText>
                            <div className={styles.listBulletSpotifyIdContainer}>
                                <span className={styles.listBulletsHeading}>
                                    Spotify Id:&nbsp;
                                </span>
                                {`${song.spotifySongId || 'N/A'}`}
                            </div>
                        </ListItemText>
                        <ListItemText>
                            <div>
                                <span className={styles.listBulletsFontBoldProperties}>
                                    Genre:&nbsp;
                                </span>
                                {`${song.genre || 'N/A'}`}
                            </div>
                        </ListItemText>
                        <ListItemText>
                            <div>
                                <span className={styles.listBulletsFontBoldProperties}>
                                    Producers:&nbsp;
                                </span>
                                {` ${song.producers || 'N/A'}`}
                            </div>
                        </ListItemText>
                        <ListItemText>
                            <div>
                                <span className={styles.listBulletsFontBoldProperties}>
                                    Writers:&nbsp;
                                </span>
                                {`${song.writers || 'N/A'}`}
                            </div>
                        </ListItemText>
                        <ListItemText>
                            <div>
                                <AdditionalIsrcsUpcs data={song.isrcs} text='ISRC' />
                            </div>
                        </ListItemText>
                        <ListItemText>
                            <div>
                                <AdditionalIsrcsUpcs data={song.upcs} text='UPC' />
                            </div>
                        </ListItemText>
                        <ListItemText>
                            <div>
                                <span className={styles.listBulletsFontBoldProperties}>
                                    Album:&nbsp;
                                </span>
                                {`${song.album || 'N/A'}`}    
                            </div>
                        </ListItemText>
                        <ListItemText>
                            <div>
                                <span className={styles.listBulletsFontBoldProperties}>
                                    Release Date:&nbsp;
                                </span>
                                {`${song.releaseDate ? song.releaseDate.split('T')[0] : 'N/A'}`} 
                            </div>
                        </ListItemText>
                    </ListItem>
                </List>
            </Collapse>
        </>
    );
}