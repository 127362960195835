import React, { useEffect, useState } from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'
import { QuarterPicker } from '../../CoreComponents/QuarterPicker';
import { getData } from '../../utils/FetchUtils';
import { useStoreContext } from '../../../store/Store';
import AccountHolderAutocomplete from '../../Earnings/AccountHolderAutocomplete';
import clsx from 'clsx';
import RoyaltyStatementsDataTable from './RoyaltyStatementsDataTable';


const useStyles = makeStyles((theme) => ({
  selectsAndToolbarContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
  },
  selectGroup: {
    width: '100%',
  },
  defaultSelect: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    color: theme.palette.text.lightGray,
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginLeft: theme.spacing(4),
    rowGap: '52.5px'
  },
  dateSelectOneAccHolder: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    color: theme.palette.text.lightGray,
    gap: 10,
    '& .MuiButtonBase-root': {
      color: 'white'
    }
  },
  dateSelect: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    color: theme.palette.text.lightGray,
    gap: 10,
    '& .MuiButtonBase-root': {
      color: 'white'
    }
  },
  defaultSelectLabel: {
    font: 'normal normal normal 14px/16px Roboto',
    color: theme.palette.text.lightYellow,
    letterSpacing: '1px',
    marginRight: theme.spacing(4),
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between'
  },
  navContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.text.lightGray,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  filtersWrapper: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '40px',
    alignItems: 'center'
  },
  dateAlert: {
    color: theme.palette.primary.alert
  },
  topSideButtonsBeforeSelect: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    columnGap: '15%',
    marginTop: '50px'
},
topSideButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    columnGap: '15%',
    width: '60%'
},
buttonWithLabelWrapper: {
  display: 'flex',
  flexDirection: 'row',
  columnGap: '20px',
  alignItems: 'center',
  '& .MuiButton-label': {
      height: '35px'
  }
},
beforeSelecting: {
  marginRight: '520px'
},
}))

const CsvStatements = () => {
  const styles = useStyles();
  const [state, setState] = useStoreContext();

  const accountHolder = useState(0);
  const [accountHolders, setAccountHolders] = useState([]);
  const [selectedAccountHolders, setSelectedAccountHolders] = useState([]);
  const [disableAccountHolderAutoComplete, setDisableAccountHolderAutoComplete] = useState(false);

  const [startQuarter, setStartQuarter] = useState(state.quarterPickerStartDate);
  const [endQuarter, setEndQuarter] = useState(Number(new Date().getFullYear() + `${Math.ceil((new Date().getMonth() + 1) / 3)}`));

  const [selectedFilter, setSelectedFilter] = useState(1);

  const [minDateValue, setMinDateValue] = useState(startQuarter);
  const [maxDateValue, setMaxDateValue] = useState(endQuarter);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    const getAccountHolder = async () => {
      const data = await getData(process.env.REACT_APP_SERVER_HOST + `/api/account-holder/my`)
      setAccountHolders(data);
    }
    getAccountHolder();
  }, []);

  useEffect(() => {
    if (startQuarter > endQuarter)
      setAlert('Start quarter is bigger than end quarter!')
    else
      setAlert(null)

  }, [startQuarter, endQuarter])

  useEffect(() => {
    if (accountHolders && accountHolders.length === 1)
      accountHolder[1](accountHolders[0].id)
  }, [accountHolders])

  useEffect(() => {
    setMinDateValue(startQuarter);
  }, [startQuarter])

  useEffect(() => {
    setMaxDateValue(endQuarter);
  }, [endQuarter])

  const handleResetAutoCompletes = () => {
  }

  return (
    <div className={styles.selectsAndToolbarContainer}>
      <div className={styles.selectGroup}>
        <div className={styles.defaultSelect}>
          <div className={styles.checkboxContainer}>
            <div className={accountHolders && accountHolders.length < 2 ? styles.dateSelectOneAccHolder : styles.dateSelect}>
              <div className={accountHolders && accountHolders.length < 2 ? styles.defaultSelectLabelOneAccHolder : styles.defaultSelectLabel}>Date Range</div>
              <QuarterPicker value={startQuarter} maxVal={maxDateValue} onChange={setStartQuarter} />
              <div className={styles.defaultSelectLabelTo}>to</div>
              <QuarterPicker value={endQuarter} minVal={minDateValue} onChange={setEndQuarter} />
            </div>
          </div>
          {
            alert ?
              <div className={styles.dateAlert}>{alert}</div>
              :
              null
          }
        </div>

        <div className={styles.toolbar}>
          <div style={{ display: 'inherit', width: '100%' }}>
            <div className={styles.navContainer}>
              <div className={styles.filtersWrapper}>
                <Box className={styles.topSideButtonsBeforeSelecttopSideButtons}>
                  <Box className={clsx(styles.buttonWithLabelWrapper, selectedAccountHolders.length === 0 ? styles.beforeSelecting : '')}>
                        <>
                          <Typography>Artist's Account holders(s) </Typography>
                          <AccountHolderAutocomplete
                            disableAccountHolderAutoComplete={disableAccountHolderAutoComplete}
                            selectedOptions={selectedAccountHolders}
                            setSelectedOptions={setSelectedAccountHolders}
                            handleResetAutoCompletes={handleResetAutoCompletes}
                          />
                          <Typography>Label's Account holders(s) </Typography>
                          <AccountHolderAutocomplete
                            disableAccountHolderAutoComplete={disableAccountHolderAutoComplete} //todo
                            selectedOptions={selectedAccountHolders} //todo
                            setSelectedOptions={setSelectedAccountHolders} //todo
                            handleResetAutoCompletes={handleResetAutoCompletes}
                          />
                        </>
                  </Box>
                </Box>
              </div>
            </div>
          </div>
        </div>
        <RoyaltyStatementsDataTable/>
        {/* table here */}
      </div>
    </div>
  )
}

export default CsvStatements