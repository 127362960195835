import { AppBar, Box, Tab, Tabs, makeStyles } from '@material-ui/core';
import React, { useState } from 'react'
import RoyaltyStatements from './RoyaltyStatements/RoyaltyStatements';
import CsvStatements from './CsvStatements/CsvStatements';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        minWidth: '1275px',
        '& .MuiInputBase-root': {
            background: 'none',
            border: `1px solid ${theme.palette.primary.darkGrayNuance}`,
            minWidth: '215px',

        }
    },
    bar: {
        '& .MuiAppBar-colorPrimary': {
            backgroundColor: theme.palette.background.default,
        },
        '& .MuiPaper-elevation4': {
            boxShadow: 'none !important',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.main
        },
        "& .MuiButtonBase-root": {
            color: 'white',
            opacity: 1,
            font: 'normal normal bold 28px/21px Roboto',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            minWidth: '300px',
            textTransform: 'none',
        },
        "& .Mui-selected": {
            color: theme.palette.primary.main,
        },
        marginBottom: theme.spacing(5),
    },
}));

const ReportingPage = () => {
    const styles = useStyles();
    const [tabValue, setTabValue] = useState(0);

    const NavOptions = [
        { label: "CSV Statements", tabProps: {} },
        // { label: "Royalty Statements", tabProps: {} },
    ];

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <div className={styles.container}>
            <div className={styles.bar}>
                <AppBar position="static">
                    <Tabs value={tabValue} onChange={handleTabChange}>
                        {NavOptions.map((tab, index) => (
                            <Tab key={index} label={tab.label} {...tab.tabProps} />
                        ))}
                    </Tabs>
                </AppBar>
            </div>

            <Box>
                {tabValue === 0 && <CsvStatements />}
                {/* {tabValue === 1 && <RoyaltyStatements />} */}
            </Box>
        </div>
    )
}

export default ReportingPage