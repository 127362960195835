import { useEffect, useRef, useState } from "react";
import { Typography, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { QuarterPicker } from '../CoreComponents/QuarterPicker';
import { BrandAutocomplete } from "../CoreComponents/BrandAutoComplete";
import { TempBrandButton } from "../CoreComponents/BrandButton";
import useAbortableFetch from '../../hooks/useAbortableFetch.js';

const useStyles = makeStyles((theme) => ({
    pageMainHeader: {
        fontSize: '25px',
        fontWeight: 'bold',
        marginBottom: '30px'
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px',
        alignItems: 'flex-start',
        '& .MuiButton-root': {
            textTransform: 'capitalize'
        }
    },
    mainWrapper: {
        marginTop: theme.spacing(3)
    },
    filterWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(3),
        marginTop: '30px'
    },
    autocompleteWrapper: {
        width: '275px',
    },
    filterLabel: {
        color: theme.palette.text.lightYellow,
    },
    notesField: {
        width: '500px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.text.lightYellow
            },
            '&:hover fieldset': {
                borderColor: theme.palette.text.lightYellow
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.text.lightYellow
            },
        },
    },
    requiredChar: {
        color: theme.palette.primary.alert
    }
}));

export default function FiltersSetupStep({
    selectedFitlers,
    setSelectedFitlers
}) {
    const styles = useStyles();
    const abortableFetch = useAbortableFetch();

    const [payorAccountHolders, setPayorAccountHolders] = useState([]);
    const [payeeAccountHolders, setPayeeAccountHolders] = useState([]);
    const [minDateValue, setMinDateValue] = useState(selectedFitlers.startDate);
    const [maxDateValue, setMaxDateValue] = useState(selectedFitlers.endDate);

    useEffect(() => {
        setMinDateValue(selectedFitlers.startDate);
    }, [selectedFitlers.startDate])

    useEffect(() => {
        setMaxDateValue(selectedFitlers.endDate);
    }, [selectedFitlers.endDate])

    useEffect(() => {
        abortableFetch('GET', '/api/account-holder/my')
            .then(data => {
                setPayorAccountHolders(data);
            });
    }, []);

    useEffect(() => {
        if (selectedFitlers.payorId)
            abortableFetch('GET', '/api/account-holder/payees', {
                query: { payorId: selectedFitlers.payorId}
            })
                .then(data => {
                    setPayeeAccountHolders(data);
                });
    }, [selectedFitlers.payorId]);

    return (
        <>
            <div className={styles.filterWrapper}>
                <div className={styles.filterLabel}>Payor (Label) <span className={styles.requiredChar}>*</span></div>
                <div className={styles.autocompleteWrapper}>
                    <BrandAutocomplete
                        placeholder={'Select Account Holder'}
                        selectedAutoCompleteOption={selectedFitlers.payorId}
                        onSelectedAutoCompleteOption={(newValue) => setSelectedFitlers({ ...selectedFitlers, payorId: newValue })}
                        autoCompleteOptions={payorAccountHolders}
                    />
                </div>
                <div className={styles.filterLabel}>Payee (Artist) <span className={styles.requiredChar}>*</span></div>
                <div className={styles.autocompleteWrapper}>
                    <BrandAutocomplete
                        placeholder={'Select Account Holder'}
                        selectedAutoCompleteOption={selectedFitlers.payeeId}
                        onSelectedAutoCompleteOption={(newValue) => setSelectedFitlers({ ...selectedFitlers, payeeId: newValue })}
                        autoCompleteOptions={payeeAccountHolders}
                    />
                </div>
                <div className={styles.filterLabel}>Date Range <span className={styles.requiredChar}>*</span></div>
                <QuarterPicker value={selectedFitlers.startDate} maxVal={maxDateValue} onChange={(newValue) => setSelectedFitlers({ ...selectedFitlers, startDate: newValue })} />
                <div className={styles.filterLabel}>to</div>
                <QuarterPicker value={selectedFitlers.endDate} minVal={minDateValue} onChange={(newValue) => setSelectedFitlers({ ...selectedFitlers, endDate: newValue })} />
            </div>
            <div className={styles.filterWrapper}>
                <div className={styles.notesField}>
                    <div>Subtitle (Optional)</div>
                    <TextField
                        value={selectedFitlers.subtitle}
                        onChange={(e) => setSelectedFitlers({ ...selectedFitlers, subtitle: e.target.value })}
                        id="outlined-multiline-static"
                        multiline
                        minRows={5}
                        variant="outlined"
                        fullWidth={true}
                        maxRows={15}
                    />
                </div>
            </div>
        </>
    );
}