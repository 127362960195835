import React, { useState, useEffect, useMemo, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, TextField, Typography } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { debounce, getData } from '../utils/FetchUtils'
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { BrandInput } from '../CoreComponents/BrandInput';
import { isObject } from 'lodash';

export function ArtistsAutoComplete({ label, onSelectedArtist }) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setOpen(inputValue.length > 0);
        (async () => {
            if (!inputValue || !isLoading) return;
            const data = await getData(process.env.REACT_APP_SERVER_HOST + `/api/spotify/search-artist?name=${inputValue}`)
            setIsLoading(false);
            setOptions(data.artists.items);
        })();
    }, [inputValue, isLoading]);

    const debounceMemo = useMemo(
        () => debounce(
            (event, newInputValue) => {
                setOptions([]);
            }, (event, newInputValue) => {
                setIsLoading(newInputValue.length > 0);
                setInputValue(newInputValue);
            }, 500),
        [],
    );

    return (
        <Autocomplete
            open={open}
            onClose={() => {
                setOpen(false);
                setOptions([]);
            }}

            value={value}
            onChange={(e, newValue) => {
                setValue('');
                setInputValue('');

                if (newValue) {
                    const { images, name, id } = newValue;

                    const artist = {
                        img: images.length > 0 ? images[0].url : null,
                        name,
                        id
                    };

                    onSelectedArtist(artist);
                }
                
                setOpen(false);
                setIsLoading(false);
            }}
            onInputChange={debounceMemo}

            blurOnSelect={true}
            clearOnBlur={true}
            clearOnEscape={true}

            getOptionLabel={(option) => option?.name || ''}
            getOptionSelected={() => false}
            renderOption={(artist, option) => {
                return (<ListItem key={artist.id}>
                    <ListItemAvatar>
                        <Avatar
                            alt={`Avatar of ${artist.name}`}
                            src={artist.images.length > 0 ? artist.images[0].url : null}
                        />
                    </ListItemAvatar>
                    <ListItemText primary={artist.name} />
                </ListItem>)

            }}
            options={options}
            loading={isLoading}
            renderInput={(params) => (
                <BrandInput
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

export function UserAutoComplete({ label, onSetUser }) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setOpen(inputValue.length > 0);
        (async () => {
            if (!inputValue || !isLoading) return;
            const data = await getData(process.env.REACT_APP_SERVER_HOST + `/api/user/?filter=${inputValue}&offset=0&limit=1000`)
            setIsLoading(false);
            setOptions(data.items);

        })();
    }, [inputValue, isLoading]);

    const debounceMemo = useMemo(
        () => debounce(
            (event, newInputValue) => {
                setOptions([]);
            }, (event, newInputValue) => {
                setIsLoading(newInputValue.length > 0);
                setInputValue(newInputValue);
            }, 500),
        [],
    );

    return (
        <Autocomplete
            open={open}
            onClose={() => {
                setOpen(false);
                setOptions([]);
            }}
            value={value}
            onInputChange={debounceMemo}
            blurOnSelect={true}
            clearOnBlur={true}
            clearOnEscape={true}

            onChange={(e, newValue) => {
                setValue('');
                setInputValue('');
                onSetUser({ username: newValue.email, id: newValue.id });
                setOpen(false);
                setIsLoading(false);
            }}
            getOptionLabel={(option) => option?.email || ''}
            getOptionSelected={() => false}
            renderOption={(user, option) => {
                return (<ListItem key={user.id}>
                    <ListItemText primary={user.email} />
                </ListItem>)
            }}
            options={options}
            loading={isLoading}
            renderInput={(params) => (
                <BrandInput
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

const brandAutocompleteStyles = makeStyles(theme => ({
    autocomplete: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
        width: '100%',
        marginBottom: '8px'
    },
    optionsContainer: {
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                border: `1px  ${theme.palette.primary.border}`,
            }
        },
        '& .MuiInputBase-root': {
            height: theme.spacing(5),
            border: '1px solid grey',
            borderRadius: theme.spacing(3),
            height: '40px'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.border,
            border: 'none'
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: theme.palette.primary.contrastText
        },
        '& .MuiInputBase-input': {
            position: 'relative',
            bottom: theme.spacing(1),
        },
        '& .MuiAutocomplete-clearIndicator': {
            color: theme.palette.primary.contrastText
        },
        '& .MuiAutocomplete-popupIndicator': {
            color: theme.palette.primary.contrastText,
            marginRight: '0px',
            "& span": {
                "& svg": {
                    fontSize: '2.1875rem',
                    "& path": {
                        d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')"
                    }
                }
            }
        },
        '& .MuiAutocomplete-endAdornment': {
            right: '0px !important',
            top: '0px'
        }
    }
}));

export function BrandAutocomplete({
    placeholder,
    heading,
    selectedAutoCompleteOption,
    onSelectedAutoCompleteOption,
    autoCompleteOptions
}) {
    const styles = brandAutocompleteStyles();
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const [open, setOpen] = useState(false);
    const elementRef = useRef();

    useEffect(() => {
        if (isObject(selectedAutoCompleteOption)) {
            setSelectedValue(selectedAutoCompleteOption);
        } else if (Number(selectedAutoCompleteOption) && autoCompleteOptions.length > 0) {
            setSelectedValue(autoCompleteOptions.find((option) => option.id === selectedAutoCompleteOption));
        } else if (Number(selectedAutoCompleteOption) === 0) {
            setSelectedValue('');
        }
    }, [selectedAutoCompleteOption, autoCompleteOptions]);

    const defaultProps = {
        options: options,
        getOptionLabel: (option) => option?.name ? option.name : '',
        getOptionSelected: (option, value) => value !== '' ? option.name === value.name : value === ''
    };

    const handleOnchange = (event, newValue) => {
        if (!newValue) {
            if (isObject(selectedAutoCompleteOption)) {
                onSelectedAutoCompleteOption({});
            } else {
                onSelectedAutoCompleteOption(0);
            }
            setSelectedValue('');
            return;
        }
        if (isObject(selectedAutoCompleteOption)) {
            onSelectedAutoCompleteOption(newValue);
        } else {
            onSelectedAutoCompleteOption(newValue.id);
        }
        setSelectedValue(newValue);
    };

    useEffect(() => {
        if (autoCompleteOptions?.length) {
            setOptions(autoCompleteOptions);
        }
    }, [autoCompleteOptions]);

    return (
        <Box className={styles.autocomplete}>
            <Typography>{heading}</Typography>
            <Autocomplete
                ref={elementRef}
                className={styles.optionsContainer}
                value={selectedValue}
                onChange={(event, newValue) => handleOnchange(event, newValue)}
                renderTags={() => null}
                getOptionLabel={(option) => option.name}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder={placeholder}
                    />
                }
                {...defaultProps}
            />
        </Box>
    );
}