import React, { useState } from 'react';
import { BrandModal } from '../../CoreComponents/BrandModal';
import { Box, Button, CircularProgress, makeStyles, Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import { TempBrandButton, TempBrandDeleteButton, TempSecondaryBrandButton } from '../../CoreComponents/BrandButton';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';


const useStyles = makeStyles((theme) => ({
    container: {
        width: 600,
    },
    title: {
        font: 'normal normal bold 24px/21px Roboto',
    },
    text: {
        font: 'normal normal normal 16px/26px Roboto',
        letterSpacing: 0,
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(1.5),
    },
    warningText: {
        marginTop: theme.spacing(3.5),
        marginBottom: theme.spacing(7),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.palette.primary.attention,
        '& > .MuiTypography-root': {
            marginLeft: theme.spacing(1),
        },

    },
    warningIcon: {
        marginRight: '0.4vw',
    },
    actionsWrapper: {
        display: 'flex',
        justifyContent: "flex-end",
        marginTop: theme.spacing(1),
        "& button:first-child": {
            marginRight: theme.spacing(2),
        },
    },
    okayButton: {
        justifyContent: "flex-end",
        marginTop: theme.spacing(7),
        display: 'flex',
    },
    loader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%', 
    }
}));

const DeleteReportModal = ({
    open,
    onClose,
    onDelete,
    reportName,
}) => {
    const styles = useStyles();

    // 0 - Confirmation
    // 1 - Success
    // 2 - Failure
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);

    const handleDelete = async () => {
        try {
            setLoading(true);
            await onDelete();
            setActiveStep(1);
        }
        catch (err) {
            setActiveStep(2);
        }
        finally {
            setLoading(false);
        }
    }

    const handleClose = () => {
        onClose();
    
        // wait for the modal to close before changing the step
        setTimeout(() => {
            setActiveStep(0);
        }, 350);
    }

    return (
        <BrandModal open={open} onClose={onClose}>
            {activeStep === 0 && (

                <Box className={styles.container}>
                    <Typography variant="h5" gutterBottom>
                        Delete CSV Report
                    </Typography>
                    {
                        loading ? (
                            <Box className={styles.loader}> 
                                <CircularProgress/>
                            </Box>
                        ) : (
                            <>
                                <Box className={styles.text}>
                                    <Typography variant="body1" gutterBottom>
                                        Are you sure you want to delete CSV report:
                                    </Typography>
                                    <Typography variant="body1">
                                        {reportName}
                                    </Typography>

                                    <Box className={styles.warningText}>
                                        <WarningAmberIcon />
                                        <Typography>This action cannot be undone.</Typography>
                                    </Box>
                                </Box>

                                <Box className={styles.actionsWrapper}>
                                    <TempSecondaryBrandButton onClick={handleClose}>
                                        Cancel
                                    </TempSecondaryBrandButton>
                                    <TempBrandDeleteButton onClick={handleDelete}>
                                        Delete
                                    </TempBrandDeleteButton>
                                </Box>
                            </>
                        )
                    }

                </Box>
            )}
            {activeStep === 1 && (
                <Box className={styles.container}>
                    <Box className={styles.text}>

                        <Typography variant="h5" gutterBottom>
                            Success!
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            You have successfully deleted CSV report:
                        </Typography>
                        <Typography variant="body1">
                            {reportName}
                        </Typography>
                    </Box>
                    <Box className={styles.okayButton}>
                        <TempBrandButton onClick={handleClose}>
                            Okay
                        </TempBrandButton>
                    </Box>
                </Box>
            )}
            {activeStep === 2 && (
                <Box className={styles.container}>
                    <Box className={styles.text}>

                        <Typography variant="h5" gutterBottom>
                            Failure!
                        </Typography>
                        <Typography >
                            Your action was not completed. Please try again.
                        </Typography>
                    </Box>
                    <Box className={styles.okayButton}>
                        <TempBrandButton onClick={handleClose}>
                            Okay
                        </TempBrandButton>
                    </Box>
                </Box>
            )}
        </BrandModal>
    )
}

export default DeleteReportModal;