import React, { useEffect, useState, useMemo, useRef, Fragment } from 'react';
import { debounce, deleteData, getData, postData, download, putData, postFormData } from '../utils/FetchUtils';
import { formatPeriodQYear } from '../utils/ReportPeriodUtils';
import { shortenLabel } from '../utils/StringUtils';
import { BrandButton, SecondaryBrandButton, TempBrandButton, TempBrandDeleteButton, TempSecondaryBrandButton } from '../CoreComponents/BrandButton';
import { makeStyles } from '@material-ui/core/styles';
import { PersonAdd } from '@material-ui/icons';
import { AppBar, Avatar, Box, Button, CircularProgress, InputAdornment, List, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, Popover, Tab, TablePagination, Tabs, Typography } from '@material-ui/core';
import { BrandInput, BrandSearchInput } from '../CoreComponents/BrandInput';
import BrandTable from '../CoreComponents/BrandTable';
import { BrandDeleteModal, BrandModal } from '../CoreComponents/BrandModal';
import { BrandAlert } from '../CoreComponents/BrandAlert';
import { IconButton } from '@material-ui/core';
import { ListItem, ListItemText } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { BrandCheckbox } from '../CoreComponents/BrandCheckbox';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import AlbumIcon from '@material-ui/icons/Album';
import GetAppIcon from '@material-ui/icons/GetApp';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { BrandLoader, BrandLoaderDots } from '../CoreComponents/BrandLoader';
import { EthIcon } from '../Portfolio/NftPortfolio';
import { textIsEmpty } from '../utils/ValidationUtils';
import clsx from 'clsx';
import DropZone from '../upload/DropZone';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import * as Papa from 'papaparse';
import { useStoreContext } from '../../store/Store';
import EditIcon from '@material-ui/icons/Edit';
import ManageSongs from './ManageSongs';
import TablePaginationActions from '../CoreComponents/TablePaginationActions';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useDebounce } from '../../hooks/useDebounce';
import { tableRowsPerClientViewHeight } from '../Portfolio/SongPortfolio';
import { useInfiniteScrolling } from '../../hooks/useInfiniteScrolling';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import BackupIcon from '@material-ui/icons/Backup';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { QuarterPicker } from '../CoreComponents/QuarterPicker';
import { validateSingleInput } from '../utils/BlockchainUtils';
import { BrandPopover } from '../CoreComponents/BrandPopover';
import { BrandMenuItem, BrandSelect } from "../CoreComponents/BrandSelect";
import UploadLogoAccountHolder from './UploadLogoAccountHolder';
import accountHolderConstants from '../../constants/accountHolderConstants';
import { MANAGE_ACCOUNT_HOLDERS_DEFAULT_ROWS_PER_PAGE, MANAGE_ACCOUNT_HOLDERS_ROWS_PER_PAGE_OPTIONS, handleRowsPerPageChange } from '../utils/PaginationUtils';
import { ButtonSize } from '../../constants/buttonConstants';

const MAX_LENGTH_FOR_IPI_NUMBER = 19;
const MAX_LENGTH_FOR_ACCOUNT_HOLDER = 255;

const dataValidators = {
    isRequired: (value) => {
        const errors = []
        if (textIsEmpty(value)) {
            errors.push('Value must not be empty.')
        }
        return errors;
    }
}

const useStyles = makeStyles(theme => ({
    input: {
        width: theme.spacing(55.625),
    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        textAlign: 'center',
        margin: 'auto auto 16px auto',
    },
    leftSide: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        "& .MuiButtonBase-root": {
            color: 'black'
        }
    },
    container: {
        "& .MuiTablePagination-root": {
            color: 'white',
            '& .MuiTablePagination-selectRoot': {
                "& .MuiSvgIcon-root": {
                    color: 'white'
                }
            }
        },
        '& .MuiTableCell-root': {
            padding: '3px 16px'
        },
        '& .MuiTableCell-head': {
            padding: '16px'
        }
    },
    root: {
        paddingLeft: theme.spacing(4)
    },
    list: {
        width: '100%',
        height: theme.spacing(50),
        overflow: 'auto'
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    modalLayout: {
        minWidth: theme.spacing(50),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2)
    },
    search: {
        "& .MuiInputBase-root": {
            width: '100%',
        }
    },
    saveMsg: {
        marginBottom: theme.spacing(8.5)
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(2),
        "& button": {
            width: '80px'
        },
    },
    accHolderActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(2),
        marginLeft: '10px',
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(3),
        '& .MuiButton-outlinedPrimary:hover': {
            border: `1px solid ${theme.palette.text.lightYellow}`
        },
        "& button": {
            width: '183px',
        },
    },
    accHolderContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        minWidth: '20vw',
    },
    deleteText: {
        marginBottom: theme.spacing(8.5)
    },
    downloadReportsHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    downloadBtn: {
        width: theme.spacing(20),
        height: theme.spacing(4),
        color: theme.palette.text.dark,
        font: 'normal normal normal 14px Roboto !important',
        textTransform: 'none'
    },
    bar: {
        '& .MuiAppBar-colorPrimary': {
            backgroundColor: theme.palette.background.default,
        },
        '& .MuiPaper-elevation4': {
            boxShadow: 'none !important',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.background.lime
        },
        '& .MuiBox-root': {
            padding: 0
        },
        "& .MuiButtonBase-root": {
            opacity: 1,
            font: 'normal normal bold 16px/20px Roboto',
            letterSpacing: '1.6px'
        },
        "& .Mui-selected": {
            color: theme.palette.background.lime,
        }
    },
    tabPanel: {
        padding: theme.spacing(2),
        height: '624px'
    },
    checkboxLabel: {
        display: 'flex',
        alignItems: 'center'
    },
    attachAndDetachNftsToolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    attachAndDetachNftsCheckbox: {
        marginLeft: '6px'
    },
    attachAndDetachNftsContainer: {
        width: '621px'
    },
    tabPanelContainerForAttachAndDetachNftsContainer: {
        display: 'flex',
        gap: theme.spacing(3)
    },
    exportNftCsvContainer: {
        paddingTop: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    contentContainer: {
        overflow: 'auto',
        height: '100%',
        width: '100%',
        padding: '2rem',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'flex-start'
    },
    uploadContainer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    upload: {
        width: 'auto',
        display: 'flex',
        alignItems: 'center'
    },
    uploadedFilesTitle: {
        marginLeft: '15px',
        textOverflow: 'ellipsis',
        font: 'normal normal bold 10px / 12px Proxima Nova',
        letterSpacing: '1.5px',
        color: theme.palette.primary.lightYellow,
        textTransform: 'uppercase',
        opacity: 1,

    },
    uploadedFilesHeader: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        justifyContent: 'space-between'
    },
    uploadedFiles: {
        marginLeft: '-15px',
    },
    uploadedFilesContainer: {
        overflow: 'auto',
        maxHeight: '350px'
    },
    nftMetadataContainer: {
        width: '1265px'
    },
    nftTitle: {
        marginLeft: '12px'
    },
    nftExportAndImportContainer: {
        height: '514px'
    },
    manageOwnershipContainer: {
        width: '1265px',
        "& .MuiTablePagination-root": {
            '& .MuiTablePagination-selectRoot': {
                "& .MuiSvgIcon-root": {
                    color: 'white'
                }
            }
        }
    },
    manageOwnershipSearch: {
        "& .MuiInputBase-root": {
            width: '46%',
        }
    },
    manageOwnershipSalesInput: {
        width: '33%',
        display: 'flex',
        justifyContent: 'space-between',
        '& .MuiInputBase-input': {
            textAlign: 'center',
        },
        paddingRight: theme.spacing(3)
    },
    ownershipAlert: {
        color: theme.palette.primary.alert,
    },
    editAccountHolderNameContainer: {
        cursor: 'pointer',
        marginRight: theme.spacing(-25)
    },
    editAccountHolderNameButton: {
        display: 'flex',
        gap: theme.spacing(0.5),
        alignItems: 'center',
        width: theme.spacing(44),
        '& .MuiButtonBase-root': {
            padding: '6px'
        }
    },
    downloadReportContainer: {
        width: '1265px'
    },
    bulkEditButton: {
        display: 'flex',
        width: '100%',
        gap: theme.spacing(0.5),
        alignItems: 'center',
        justifyContent: 'flex-end',
        cursor: 'pointer',
        '& .MuiButtonBase-root': {
            padding: '6px',
        },
    },
    uploading: {
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center'
    },
    attachSongButtonAndAlertContainer: {
        display: 'flex',
        gap: theme.spacing(1),
        flexDirection: 'column',
        alignItems: 'end',
        '& button': {
            width: '163px',
        }
    },
    saveMessage: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10
    },
    ownershipTitles: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: theme.spacing(1)
    },
    primaryTitle: {
        paddingRight: theme.spacing(14)
    },
    manageOwnershipInputs: {
        width: theme.spacing(13),
    },
    informationButton: {
        marginRight: theme.spacing(8),
        position: 'relative',
        display: 'inline-block',
        '& .tooltip': {
            visibility: 'hidden',
            width: '241px',
            backgroundColor: 'black',
            color: '#fff',
            textAlign: 'start',
            padding: '5px',
            borderRadius: '6px',
            position: 'absolute',
            zIndex: 1
        },
        '&:hover': {
            '& .tooltip': {
                visibility: 'visible'
            }
        },
    },
    secondaryOwnershipAlertMessage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end'
    },
    accountHolderMainTitle: {
        font: 'normal normal bold 25px Roboto',
    },
    inputsSection: {
        width: theme.spacing(43),
        // height: theme.spacing(29),
        backgroundColor: theme.palette.text.dark,
        borderRadius: '16px',

    },
    inputsContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(3),
        gap: theme.spacing(2)
    },
    cancelButton: {
        color: theme.palette.text.primary,
        borderColor: theme.palette.text.lightYellow,
        backgroundColor: theme.palette.background.default,
        borderColor: theme.palette.primary.main,
        width: theme.spacing(11.25),
        height: theme.spacing(4),
        font: 'normal normal normal 14px Roboto !important',
        textTransform: 'none',
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.background.default,
        }
    },
    saveButton: {
        width: theme.spacing(11.25),
        height: theme.spacing(4),
        color: theme.palette.text.dark,
        font: 'normal normal normal 14px Roboto !important',
        textTransform: 'none'
    },
    totalAttachedCount: {
        font: 'normal normal normal 14px Roboto !important',
        marginLeft: '85%',
        padding: '8px',
    },
    selectBtnsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '20px'
    },
    unselectBtn: {
        background: theme.palette.primary.alert,
        '&:disabled': {
            background: theme.palette.primary.alert
        },
        '&:hover': {
            background: theme.palette.primary.alert,
            opacity: 0.7
        }
    },
    actionButton: {
        fontSize: '12px !important',
        color: theme.palette.primary.contrastText,
        // '&:hover': {
        //     color: '#f42a82',
        //     backgroundColor: 'transparent'
        // },
        '& .MuiButton-startIcon': {
            marginRight: '4px'
        },
        '& .MuiButton-label': {
            fontWeight: 'normal'
        },
        textTransform: 'capitalize'
    },
    checkIconButton: {
        color: 'green',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    closeIconButton: {
        color: theme.palette.primary.alert,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    deleteMessageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '5px'
    },
    deleteWarningMessage: {
        color: theme.palette.primary.attention,
        marginTop: theme.spacing(5.5),
        position: 'absolute'
    },
    confirmationActionContainer: {
        textTransform: 'capitalize',
        fontSize: '12px !important',
    },
    periodQEditDeleteBox: {
        display: 'flex'
    },
    periodQDatePicker: {
        marginRight: '130px'
    },
    notes: {
        marginRight: '140px'
    },
    editNotesInput: {
        width: theme.spacing(26),
    },
    headingAndFilterWrapper: {
        display: 'flex'
    },
    filterWrapper: {
        marginLeft: '80px',
        display: 'flex',
        alignItems: 'center'
    },
    filterHeading: {
        marginRight: '4px'
    },
    uploadLogoWrapper: {
        display: 'flex',
        justifyContent: 'start',
        flexDirection: 'column',
        gap: '10px'
    },
    uploadLogoButton:{
        backgroundColor: theme.palette.text.dark,
        textTransform: 'none',
        boxShadow: 'none',
        maxWidth:'90%',
        fontSize: '16px',
        padding:'0',
        '&:hover':{
            backgroundColor: theme.palette.text.dark,
            color: theme.palette.primary.hover,
            boxShadow: 'none'
        },
    },
    deleteLogoButton:{
        backgroundColor: '#222222',
        textTransform: 'none',
        boxShadow: 'none',
        width:'22px',
        height:'22px',
        fontSize: '16px',
        padding:'0',
        cursor:'pointer',
        zIndex:'3',
        marginLeft: '4px',
        '&:hover':{
            backgroundColor: '#222222',
            color: theme.palette.primary.hover,
            boxShadow: 'none'
        }
    },
    logoName: {
        color: '#9E9E9E',
        fontSize: '13px',
        marginLeft: '10px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth:'80%'
    },
    hiddenInputFile:{
        display:'none'
    },
    previewLogo:{
        width:'50px',
        height:'50px',
    },
    hiddenIcon: {
        visibility:'hidden'
    },
    editAccountHolderIconBlock: {
        display: 'flex',
        alignItems: 'end',
        gap: '5px'
    },
    error: {
        paddingBottom: 10, 
        color: theme.palette.primary.main, 
        fontSize: '18px'
    }
}));

const AttachNftsModal = ({
    accountHolder,
    attachedNftsFromDetachedNft,
    setAttachedNftsFromDetachedNft,
    detachedNftsFromAttachedNfts,
    setDetachedNftsFromAttachedNfts,
    clickDetach, setClickDetach,
    setFinishedDetaching,
    setIsDetaching,
    setDetachedSuccessfully
}) => {
    const styles = useStyles();
    const [search, setSearch] = useState('');
    const [searchFilter, setFilterSearch] = useState('');
    const [nfts, setNfts] = useState([]);
    const [selectedNfts, setSelectedNfts] = useState({});
    const [saveDisabled, setSaveDisabled] = useState(true);
    const selectAll = useState(false);
    const [initiallyLoaded, setInitiallyLoaded] = useState(false);
    const [clickAttach, setClickAttach] = useState(false);
    const [finishedAttachingAlert, setFinishedAttaching] = useState('');
    const [isAttaching, setIsAttaching] = useState(false);
    const [attachedSuccessfully, setAttachedSuccessfully] = useState(false);

    const [LIMIT] = useState(10);

    const elementRef = useRef(null);
    const offsetRef = useRef(0);

    const [setIsFetching, setIsDisabled] = useInfiniteScrolling(elementRef, async () => await handleFetchData());

    async function handleFetchData() {
        try {
            const { items } = await postData(process.env.REACT_APP_SERVER_HOST + `/api/nft/nfts-search?offset=${offsetRef.current}&limit=${LIMIT}&filter=${searchFilter}&accountHolder=${accountHolder.id}`, { attachedNfts: attachedNftsFromDetachedNft });

            if (items.length === 0) {
                setIsDisabled(true);
                return;
            }

            setNfts((prev) => prev.concat(items));

            offsetRef.current += LIMIT;
            setIsFetching(false);
            setIsDisabled(false);

        } catch (error) {
            console.log(error.message);
        }
    }

    useEffect(() => {
        setIsDisabled(true);
        offsetRef.current = 0;
        handleFetchData();
    }, []);

    useEffect(() => {
        if (initiallyLoaded) {
            setNfts([]);
            setSelectedNfts({});
            offsetRef.current = 0;
            handleFetchData();
        }
        setInitiallyLoaded(true);
        setIsDisabled(true);
    }, [searchFilter]);

    useEffect(() => {
        if (finishedAttachingAlert) {
            setTimeout(() => {
                setFinishedAttaching('');
            }, "4000")
        }
    }, [finishedAttachingAlert])

    useEffect(() => {
        const selectedNftsCount = Object.keys(selectedNfts).length;
        if (selectedNftsCount && selectedNftsCount === nfts.length) {
            selectAll[1](true);
        } else {
            selectAll[1](false);
        }

        if (selectedNftsCount) {
            setSaveDisabled(false);
        } else {
            setSaveDisabled(true);
        }
    }, [selectedNfts]);

    const debounceMemo = useMemo(
        () => debounce(
            (event) => {
                setSearch(event.target.value);
            }, (event) => {
                if (event.target.value.includes('#')) {
                    setFilterSearch(event.target.value.replaceAll('#', '%23'))
                } else {
                    setFilterSearch(event.target.value);
                }
            }, 500),
        [],
    );

    function onClickSelectAll() {
        if (!selectAll[0]) {
            const tempObj = {};
            for (const nft of nfts) {
                tempObj[nft.id] = 1;
            }
            const allNftCheckboxes = document.getElementsByClassName('nftCheckbox');
            for (const nftCheckbox of allNftCheckboxes) {
                const nftCheckboxEle = nftCheckbox.getElementsByTagName('input')[0];
                if (!nftCheckboxEle.checked) {
                    nftCheckboxEle.click();
                }
            }
            setSelectedNfts(tempObj);
        } else {
            const allNftCheckboxes = document.getElementsByClassName('nftCheckbox');
            for (const nftCheckbox of allNftCheckboxes) {
                const nftCheckboxEle = nftCheckbox.getElementsByTagName('input')[0];
                if (nftCheckboxEle.checked) {
                    nftCheckboxEle.click();
                }
            }
            setSelectedNfts({});
        }
    }

    function onClickAttach() {
        setIsAttaching(true)
        setClickAttach(true)
        const tempAttachedNfts = attachedNftsFromDetachedNft;
        let tempDetachedNfts = detachedNftsFromAttachedNfts;
        let nftsInTheSystem = nfts;

        for (const nft of nfts) {
            const nftInAttachedColumn = tempAttachedNfts.filter(n => n.id === nft.id);
            if (selectedNfts[nft.id] && nftInAttachedColumn.length === 0) {
                tempAttachedNfts.unshift(nft);
                nftsInTheSystem = nftsInTheSystem.filter(n => n.id !== nft.id);
            }

            tempDetachedNfts = tempDetachedNfts.filter(detachedNft => detachedNft.id !== nft.id);

        }

        setDetachedNftsFromAttachedNfts([...tempDetachedNfts]);
        setAttachedNftsFromDetachedNft([...tempAttachedNfts]);
        setNfts(nftsInTheSystem);
        if(nftsInTheSystem.length < 8){
            handleFetchData();
        }

        const allNftCheckboxes = document.getElementsByClassName('nftCheckbox');
        for (const nftCheckbox of allNftCheckboxes) {
            const nftCheckboxEle = nftCheckbox.getElementsByTagName('input')[0];
            if (nftCheckboxEle.checked) {
                nftCheckboxEle.click();
            }
        }
        setAttachedSuccessfully(true);
        setFinishedAttaching('Attached successfully');
        setSelectedNfts({});
        setIsAttaching(false);
    }

    function onClickListItem(e, nftId) {
        const nftCheckbox = document.getElementById(nftId);
        const checkboxElement = nftCheckbox.getElementsByTagName('input')[0];
        checkboxElement.click();
    }

    return (
        <div className={styles.modalLayout}>
            <div className={styles.search}>
                <BrandSearchInput className={styles.search} value={search} onChange={debounceMemo} />
            </div>
            <div className={styles.attachAndDetachNftsToolbar}>
                <div className={styles.checkboxLabel}>
                    <BrandCheckbox onClick={onClickSelectAll} $value={selectAll} className={styles.attachAndDetachNftsCheckbox} />
                    <Typography variant='subtitle1' component='div'> Select All</Typography>
                </div>
                <div className={styles.attachSongButtonAndAlertContainer}>
                    <TempBrandButton
                        size={ButtonSize.SMALL}
                        disabled={saveDisabled}
                        onClick={onClickAttach}
                    >
                        {isAttaching ?
                            <div className={styles.uploading}>
                                <BrandLoader color='white' width={20} height={20} />
                                <div>Attaching</div>
                            </div>
                            :
                            'Attach NFT'
                        }
                    </TempBrandButton>
                    {finishedAttachingAlert ? <BrandAlert success={attachedSuccessfully}>{finishedAttachingAlert}</BrandAlert> : null}
                </div>
            </div>
            <List className={styles.list} ref={elementRef}>
                {nfts.map(nft => {
                    return (
                        <ListItem
                            key={nft.id}
                            onClick={(e) => onClickListItem(e, nft.id)}
                        >
                            <BrandCheckbox
                                className='nftCheckbox'
                                id={nft.id}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    const tempSelectedNfts = { ...selectedNfts };
                                    if (e.target.checked) {
                                        tempSelectedNfts[nft.id] = 1;
                                    } else {
                                        delete tempSelectedNfts[nft.id];
                                    }
                                    setSelectedNfts(tempSelectedNfts);
                                }}
                            />
                            <ListItemAvatar>
                                {nft.coverArt ?
                                    <Avatar
                                        alt={`Avatar of ${nft.title}`}
                                        src={nft.coverArt}
                                    />
                                    :
                                    <AlbumIcon fontSize='large' />
                                }
                            </ListItemAvatar>
                            <ListItemText
                                primary={nft.title}
                            />
                        </ListItem>
                    )
                })}
            </List>
        </div >
    );
}

const DetachNFTsModal = ({
    accountHolder,
    attachedNftsFromDetachedNft,
    setAttachedNftsFromDetachedNft,
    detachedNftsFromAttachedNfts,
    setDetachedNftsFromAttachedNfts,
    setOriginalNftData,
    finishedDetachingAlert,
    setFinishedDetaching,
    isDetaching,
    setIsDetaching,
    detachedSuccessfully,
    setClickDetach,
    setTotal,
    setDetachedSuccessfully
}) => {
    const styles = useStyles();
    const debounceMemo = useMemo(
        () => debounce(
            (event) => {
                setSearch(event.target.value);
            }, (event) => {
                if (event.target.value.includes('#')) {
                    setFilterSearch(event.target.value.replaceAll('#', '%23'))
                } else {
                    setFilterSearch(event.target.value);
                }
            }, 500),
        [],
    );
    const [search, setSearch] = useState('');
    const [searchFilter, setFilterSearch] = useState('');
    const [selectedNfts, setSelectedNfts] = useState({});
    const [saveDisabled, setSaveDisabled] = useState(true);
    const selectAll = useState(false);
    const [filteredAttachedNftsFromDetachedNft, setFilteredAttachedNftsFromDetachedNft] = useState(undefined);
    const [refreshSelectedNfts, setRefreshSelectedNfts] = useState({});
    const [initialRefresh, setInitialRefresh] = useState(false);

    const [LIMIT] = useState(10);

    const elementRef = useRef(null);
    const offsetRef = useRef(0);

    const [setIsFetching, setIsDisabled] = useInfiniteScrolling(elementRef, async () => await handleFetchData());

    async function handleFetchData() {
        try {
            const { items, total } = await getData(process.env.REACT_APP_SERVER_HOST + `/api/nft/account-holder-nfts?accountHolder=${accountHolder.id}&offset=${offsetRef.current}&limit=${LIMIT}&filter=${searchFilter}`);

            if (items.length === 0) {
                setIsDisabled(true);
                return;
            }

            setAttachedNftsFromDetachedNft((prev) => prev.concat(items));
            setOriginalNftData((prev) => prev.concat(JSON.stringify(items)));

            setTotal(total);

            offsetRef.current += LIMIT;
            setIsFetching(false);
            setIsDisabled(false);

        } catch (error) {
            console.log(error.message);
        }
    }

    useEffect(() => {
        setRefreshSelectedNfts({});
        setIsDisabled(true);
        offsetRef.current = 0;
        handleFetchData();
    }, [])

    useEffect(() => {
        if (finishedDetachingAlert) {
            setTimeout(() => {
                setFinishedDetaching('');
            }, "4000")
        }
    }, [finishedDetachingAlert])

    useEffect(() => {
        setRefreshSelectedNfts({});
        setAttachedNftsFromDetachedNft([]);
        offsetRef.current = 0;
        handleFetchData();
    }, [searchFilter])

    useEffect(() => {
        if (initialRefresh) {
            const tempSelectedNfts = { ...selectedNfts };
            for (const selectedNft of Object.keys(selectedNfts)) {
                const checkboxParentEle = document.getElementById(selectedNft);
                if (checkboxParentEle) {
                    const checkbox = checkboxParentEle.getElementsByTagName('input')[0]
                    if (!checkbox.checked) {
                        checkbox.click();
                    }
                }
            }
            setSelectedNfts(tempSelectedNfts);
        }
        setInitialRefresh(true);
        setIsDisabled(true);
    }, [refreshSelectedNfts])

    useEffect(() => {
        const selectedNftsCount = Object.keys(selectedNfts).length;
        if (selectedNftsCount && selectedNftsCount === attachedNftsFromDetachedNft.length) {
            selectAll[1](true);
        } else {
            selectAll[1](false);
        }

        if (selectedNftsCount) {
            setSaveDisabled(false);
        } else {
            setSaveDisabled(true);
        }
    }, [selectedNfts, attachedNftsFromDetachedNft])

    function onClickSelectAll() {
        if (!selectAll[0]) {
            const tempObj = {};
            for (const nft of attachedNftsFromDetachedNft) {
                tempObj[nft.id] = 1;
            }
            const allNftCheckboxes = document.getElementsByClassName('nftAttachedCheckbox');
            for (const nftCheckbox of allNftCheckboxes) {
                const nftCheckboxEle = nftCheckbox.getElementsByTagName('input')[0];
                if (!nftCheckboxEle.checked) {
                    nftCheckboxEle.click();
                }
            }
            setSelectedNfts(tempObj);
        } else {
            const allNftCheckboxes = document.getElementsByClassName('nftAttachedCheckbox');
            for (const nftCheckbox of allNftCheckboxes) {
                const nftCheckboxEle = nftCheckbox.getElementsByTagName('input')[0];
                if (nftCheckboxEle.checked) {
                    nftCheckboxEle.click();
                }
            }
            setSelectedNfts({});
        }
    }

    function onClickDetach() {
        setIsDetaching(true);
        setClickDetach(true);
        const tempAttachedNfts = attachedNftsFromDetachedNft;

        for (let i = 0; i < tempAttachedNfts.length; i++) {
            if (selectedNfts[tempAttachedNfts[i].id]) {
                const nftToDetach = tempAttachedNfts[i];
                setDetachedNftsFromAttachedNfts(prev => [...prev, nftToDetach]);
                tempAttachedNfts.splice(i, 1)
                i = i - 1;
            }
        }

        setAttachedNftsFromDetachedNft([...tempAttachedNfts]);

        const allNftCheckboxes = document.getElementsByClassName('nftCheckbox');
        for (const nftCheckbox of allNftCheckboxes) {
            const nftCheckboxEle = nftCheckbox.getElementsByTagName('input')[0];
            if (nftCheckboxEle.checked) {
                nftCheckboxEle.click();
            }
        }
        setSearch('');
        setFilterSearch('');
        setDetachedSuccessfully(true);
        setFinishedDetaching('Detached successfully');
        setSelectedNfts({});
        setIsDetaching(false);
        handleFetchData()
    }
    function onClickListItem(e, nftId) {
        const nftCheckbox = document.getElementById(nftId);
        const checkboxElement = nftCheckbox.getElementsByTagName('input')[0];
        checkboxElement.click();
    }

    return (
        <div className={styles.modalLayout}>
            <div className={styles.search}>
                <BrandSearchInput className={styles.search} value={search} onChange={debounceMemo} />
            </div>
            <div className={styles.attachAndDetachNftsToolbar}>
                <div className={styles.checkboxLabel}>
                    <BrandCheckbox onClick={onClickSelectAll} $value={selectAll} className={styles.attachAndDetachNftsCheckbox} />
                    <Typography variant='subtitle1' component='div'> Select All</Typography>
                </div>
                <div className={styles.attachSongButtonAndAlertContainer}>
                    <TempBrandDeleteButton
                        size={ButtonSize.SMALL}
                        disabled={saveDisabled}
                        onClick={onClickDetach}
                    >
                        {
                            isDetaching ?
                                <div className={styles.uploading}>
                                    <BrandLoader color='white' width={20} height={20} />
                                    <div>Detaching</div>
                                </div>
                                :
                                'Detach NFT'
                        }
                    </TempBrandDeleteButton>
                    {finishedDetachingAlert ? <BrandAlert warning={detachedSuccessfully}>{finishedDetachingAlert}</BrandAlert> : null}
                </div>
            </div>
            <List className={styles.list} ref={elementRef}>
                {(filteredAttachedNftsFromDetachedNft !== undefined ? filteredAttachedNftsFromDetachedNft : attachedNftsFromDetachedNft).map(nft => {
                    return (
                        <ListItem
                            key={nft.id}
                            onClick={(e) => onClickListItem(e, nft.id)}
                        >
                            <BrandCheckbox
                                id={nft.id}
                                className='nftAttachedCheckbox'
                                onClick={e => {
                                    e.stopPropagation();
                                    const tempSelectedNfts = { ...selectedNfts };

                                    if (e.target.checked) {
                                        tempSelectedNfts[nft.id] = 1;
                                    } else {
                                        delete tempSelectedNfts[nft.id];
                                    }
                                    setSelectedNfts(tempSelectedNfts);
                                }}
                            />
                            <ListItemAvatar>
                                {nft.coverArt ?
                                    <Avatar
                                        alt={`Avatar of ${nft.title}`}
                                        src={nft.coverArt}
                                    />
                                    :
                                    <AlbumIcon fontSize='large' />
                                }
                            </ListItemAvatar>
                            <ListItemText
                                primary={nft.title}
                            />
                        </ListItem>
                    )
                })}
            </List>
        </div>
    );
}

const AddAccountHolder = ({ onClose }) => {
    const [state, setState] = useStoreContext();
    const styles = useStyles();
    const accountHolder = useState('');
    const walletAddress = useState('');
    const ipiNumber = useState(null);
    const [error, setError] = useState('')
    const [disableAddBtn, setDisableAddBtn] = useState(true);
    const [errorUploadLogo, setErrorUploadLogo] = useState('');
    const [formDataLogo,setFormDataLogo] = useState(null);
    const [previewLogo,setPreviewLogo] = useState(null);

    useEffect(() => {
        setError('');
        accountHolder[0].trim() === '' ? setDisableAddBtn(true) : setDisableAddBtn(false);
        isNaN(ipiNumber[0]) ? setDisableAddBtn(true) : setDisableAddBtn(false);

        if (accountHolder[0].length === MAX_LENGTH_FOR_ACCOUNT_HOLDER) {
            setError(`Name cannot be longer than ${MAX_LENGTH_FOR_ACCOUNT_HOLDER} characters`);
        } else {
            setError('');
        }

        if(ipiNumber[0]?.length > MAX_LENGTH_FOR_IPI_NUMBER || isNaN(ipiNumber[0])){
            setError('IPI number invalid format or length');
        } else {
            setError('');
        }
    }, [accountHolder[0], ipiNumber[0]])

    const handleUpload = (e) =>{
        const file = e.target.files[0];

        if(!accountHolderConstants.logo.VALIDATE_FILE_TYPES.find(type => type === file.type)){
            setErrorUploadLogo('File must be in JPG/PNG/SVG format');
            return 
        }else {
            setErrorUploadLogo('');
        }

        setPreviewLogo({URL:URL.createObjectURL(file),name:file.name});
        
        const form = new FormData();
        form.append('file',file);

        setFormDataLogo(form)
    }

    const handleDeleteLogo = () =>{
        setPreviewLogo(null);
    }

    function onSubmit(e) {
        e.preventDefault();
        postData(process.env.REACT_APP_SERVER_HOST + '/api/account-holder/', { name: accountHolder[0], walletAddress: walletAddress[0], ipiNumber: ipiNumber[0] })
            .then(data => {
                addLogoToAccountHolder(data.accountHolderId).finally(()=> onClose());
            }, error => {
                setError(error.message);
            })
    }

    async function addLogoToAccountHolder(accountHolderId){
        const form = formDataLogo
        form.append('accountHolderId',accountHolderId);
        await postFormData(process.env.REACT_APP_SERVER_HOST + `/api/account-holder/upload-account-holder-logo`, formDataLogo);
    }

    return (
        <div className={styles.accHolderContainer}>
            <form className={styles.accHolderContainer} onSubmit={onSubmit}>
                <Typography
                    variant="h6"
                    component="div"
                >
                    Add Account Holder
                </Typography>
                {error ?
                    <BrandAlert>{error}</BrandAlert>
                    :
                    null
                }
                <BrandInput
                    label='Account Holder'
                    $value={accountHolder}
                    validator={dataValidators.isRequired}
                    maxLength={MAX_LENGTH_FOR_ACCOUNT_HOLDER}
                    required
                />
                {
                    state.navType !== 'Music' ?
                        <BrandInput 
                            $value={walletAddress} 
                            label='Wallet Address' 
                        />
                        :
                        <BrandInput 
                            $value={ipiNumber} 
                            label='IPI Number(Optional)'
                            maxLength={MAX_LENGTH_FOR_IPI_NUMBER}
                        />
                }
                <UploadLogoAccountHolder styles={styles} previewLogo={previewLogo} handleUpload={handleUpload} handleDeleteLogo={handleDeleteLogo} errorUploadLogo={errorUploadLogo} />

                <div className={styles.accHolderActions}>
                    <TempSecondaryBrandButton
                        size={ButtonSize.SMALL}
                        variant='outlined'
                        onClick={() => onClose()}
                    >
                        Cancel
                    </TempSecondaryBrandButton>
                    <TempBrandButton
                        size={ButtonSize.SMALL}
                        type='submit'
                        disabled={disableAddBtn}
                    >
                        Add
                    </TempBrandButton>
                </div>
            </form>
        </div>
    );
}

const NestedListItem = ({
    item,
    itemType,
    setSelectedItems,
    selectedItems,
    refresh,
    setRefresh,
    setShowWarningMessage,
    refreshReports,
    setAlert,
    accountHolder
}) => {
    const styles = useStyles();

    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(false);
    const [deleteClicked, setDeleteClicked] = useState(false);
    const [editClicked, setEditClicked] = useState(false);
    const [processingDeletion, setProcessingDeletion] = useState(false);
    const [processingEdit, setProcessingEdit] = useState(false);

    const [endQuarter, setEndQuarter] = useState(Number(new Date().getFullYear() + `${Math.ceil((new Date().getMonth() + 1) / 3)}`));
    const [newReportQ, setNewReportQ] = useState(0);

    const [periodQ, setPeriodQ] = useState('');
    const [editedNotes, setEditedNotes] = useState(item.notes);
    const VISIBLE_NOTES_LENGTH = 27;
    const POPOVER_MAX_WIDTH = 48;

    useEffect(() => {
        if (itemType === 'period') {
            setPeriodQ(formatPeriodQYear(item[0].reportingPeriod));
        } else if (itemType === 'report') {
            setPeriodQ(formatPeriodQYear(item.reportingPeriod));
        }
    }, []);

    const onClickExpand = (e) => {
        e.stopPropagation();
        setOpen(!open);
    };

    const handleSelectItem = (
        selected = true
    ) => {
        setSelectedItems((prevState) => ({
            ...prevState,
            [item.id]: selected
        }));
    }

    const handleEditClick = () => {
        setEditClicked(true);
        handleSelectItem();
    }

    const handleDeleteClick = () => {
        setDeleteClicked(true);
        setShowWarningMessage(true);
        handleSelectItem();
    }

    const handleCancelEditClick = () => {
        setEditClicked(false);
        setNewReportQ(0);
        setAlert('');
        handleSelectItem(false);
    }

    const handleCancelDeleteClick = () => {
        setDeleteClicked(false);
        setShowWarningMessage(false);
        handleSelectItem(false);
    }

    const handleConfirmEditClick = async () => {
        setProcessingEdit(true);
        try {
            await putData(process.env.REACT_APP_SERVER_HOST + `/api/report`, {
                id: item.id,
                newQ: newReportQ,
                notes: editedNotes
            });

            await refreshReports();
            setEditClicked(false);
            handleSelectItem(false);
        } catch (error) {
            setAlert('Error updating report period, please provide new report period');
            console.log(error);
        } finally {
            setProcessingEdit(false);
        }
    }

    const handleConfirmDeleteClick = async () => {
        setProcessingDeletion(true);

        try {
            await deleteData(process.env.REACT_APP_SERVER_HOST + `/api/report`, {
                id: item.id,
                accountHolderId: accountHolder.id
            });

            await refreshReports();
            handleSelectItem(false);
        } catch (err) {
            console.log(err);
        } finally {
            setShowWarningMessage(false);
            setProcessingDeletion(false);
        }
    }

    const onCheck = (e) => {
        e.stopPropagation();
        if (!checked) {
            if (itemType === 'artist') {
                const trackObj = {}
                for (const album of item.albums) {
                    for (const track of album.tracks) {
                        trackObj[track.id] = true;
                    }
                }
                setSelectedItems(Object.assign(trackObj, selectedItems));
            } else if (itemType === 'album') {
                const trackObj = {}
                for (const track of item.tracks) {
                    trackObj[track.id] = true;
                }
                setSelectedItems(Object.assign(trackObj, selectedItems));
            } else if (itemType === 'track') {
                setSelectedItems(song => (song[item.id] = true, { ...selectedItems }));
            } else if (itemType === 'period') {
                const reportObj = {}
                for (const report of item) {
                    reportObj[report.id] = true;
                }
                setSelectedItems(Object.assign(reportObj, selectedItems));
            } else if (itemType === 'report') {
                setSelectedItems(report => (report[item.id] = true, { ...selectedItems }));
            }
        } else {
            if (itemType === 'artist') {
                const trackObj = selectedItems
                for (const album of item.albums) {
                    for (const track of album.tracks) {
                        delete trackObj[track.id];
                    }
                }
                setSelectedItems(trackObj);
            } else if (itemType === 'album') {
                const trackObj = selectedItems;
                for (const track of item.tracks) {
                    delete trackObj[track.id];
                }
                setSelectedItems(trackObj);
            } else if (itemType === 'track') {
                const trackObj = selectedItems;
                delete trackObj[item.id];
                setSelectedItems(trackObj);
            } else if (itemType === 'period') {
                const reportObj = selectedItems;
                for (const report of item) {
                    delete reportObj[report.id];
                }
                setSelectedItems(reportObj);
            } else if (itemType === 'report') {
                const reportObj = selectedItems;
                delete reportObj[item.id];
                setSelectedItems(reportObj);
            }
        }
        setChecked(!checked);
        setRefresh({});
    }

    useEffect(() => {
        if (itemType === 'artist') {
            let tmpChecked = true
            outer:
            for (const album of item.albums) {
                for (const track of album.tracks) {
                    if (!selectedItems[track.id]) {
                        tmpChecked = false;
                        break outer;
                    }
                }
            }
            setChecked(tmpChecked);
        } else if (itemType === 'album') {
            let tmpChecked = true;
            for (const track of item.tracks) {
                if (!selectedItems[track.id]) {
                    tmpChecked = false;
                    break;
                }
            }
            setChecked(tmpChecked);
        } else if (itemType === 'track') {
            setChecked(selectedItems[item.id] ? true : false)
        } else if (itemType === 'period') {
            let tmpChecked = true;
            for (const report of item) {
                if (!selectedItems[report.id]) {
                    tmpChecked = false;
                    break;
                }
            }
            setChecked(tmpChecked);
        } else if (itemType === 'report') {
            setChecked(selectedItems[item.id] ? true : false)
        }

    }, [refresh])

    return (
        <>
            <ListItem button onClick={itemType !== 'track' && itemType !== 'report' ? onClickExpand : () => {}}>
                <BrandCheckbox checked={checked} onClick={(e) => e.stopPropagation()} onChange={onCheck} reportBrandCheckBox={itemType === 'period' || itemType === 'report'} />

                {itemType === 'album' ?
                    <ListItemAvatar>
                        {item.coverArt ?
                            <Avatar
                                alt={`Avatar of ${item.name}`}
                                src={item.coverArt}
                            />
                            :
                            <AlbumIcon fontSize='large' />
                        }
                    </ListItemAvatar>
                    :
                    null
                }
                {itemType === 'artist' ?
                    <ListItemAvatar>
                        <Avatar
                            alt={`Avatar of ${item.name}`}
                            src={item.coverArt || null}
                        />
                    </ListItemAvatar>
                    :
                    null
                }
                <ListItemText primary={
                    itemType === 'period' ?
                        `Reporting Period ${periodQ}`
                        :
                        itemType === 'report' ?
                            item.fileName
                            :
                            item.name
                } />
                { itemType === 'report' && (
                    <Box className={styles.periodQEditDeleteBox}>
                        <ListItemText className={styles.periodQDatePicker}>{itemType === 'report' &&
                            (editClicked ?
                                <QuarterPicker value={newReportQ !== 0 ? newReportQ : item.reportingPeriod} maxVal={endQuarter} onChange={setNewReportQ} iconSize='medium' reportQuarterPicker={true}/>
                                :
                                `${periodQ}`)}
                        </ListItemText>
                        <ListItemText className={styles.notes}>{itemType === 'report' &&
                            (editClicked ? 
                                <BrandInput
                                    value={editedNotes}
                                    classes={{ input: styles.editNotesInput }}
                                    onChange={(e) => setEditedNotes(e.target.value)}
                                />
                            : 
                            <BrandPopover 
                                popoverData={item.notes}
                                popoverDataFormated={item.notes ? shortenLabel(item.notes, VISIBLE_NOTES_LENGTH) : ''}
                                popoverMaxWidth={POPOVER_MAX_WIDTH}
                            />
                            )}
                        </ListItemText>
                        { deleteClicked || editClicked ? (
                            <Fragment>
                                { processingDeletion || processingEdit ? (
                                    <BrandLoaderDots />
                                ) : (
                                    <Fragment>
                                        <Button
                                            className={styles.checkIconButton}
                                            startIcon={<><CheckIcon /> <div className={styles.confirmationActionContainer}>{deleteClicked ? 'Delete' : 'Edit'}</div></>}
                                            onClick={deleteClicked ? handleConfirmDeleteClick : handleConfirmEditClick}
                                            disableRipple
                                        />
                                        <Button
                                            className={styles.closeIconButton}
                                            startIcon={<><CloseIcon /><div className={styles.confirmationActionContainer}>Cancel</div></>}
                                            onClick={deleteClicked ? handleCancelDeleteClick : handleCancelEditClick}
                                            disableRipple
                                        />
                                    </Fragment>
                                ) }
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Button
                                    className={styles.actionButton}
                                    startIcon={<EditIcon />}
                                    onClick={handleEditClick}
                                    disableRipple
                                >
                                    Edit
                                </Button>
                                <Button
                                    className={styles.actionButton}
                                    startIcon={<DeleteOutlineOutlinedIcon />}
                                    onClick={handleDeleteClick}
                                    disableRipple
                                >
                                    Delete
                                </Button>
                            </Fragment>
                        ) }
                    </Box>

                ) }
                {itemType !== 'track' && itemType !== 'report' ?
                    open ?
                        <IconButton style={{ color: 'white' }} onClick={onClickExpand}>
                            <ExpandLess />
                        </IconButton>
                        :
                        <IconButton style={{ color: 'white' }} onClick={onClickExpand}>
                            <ExpandMore />
                        </IconButton>
                    : null}
            </ListItem>
            {itemType !== 'track' && itemType !== 'report' ?
                <Collapse classes={{ root: styles.root }} in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {itemType === 'period' ?
                            item.map(report => {
                                return (
                                    <NestedListItem
                                        key={report.id}
                                        item={report}
                                        itemType='report'
                                        setSelectedItems={setSelectedItems}
                                        selectedItems={selectedItems}
                                        refresh={refresh}
                                        setRefresh={setRefresh}
                                        setShowWarningMessage={setShowWarningMessage}
                                        refreshReports={refreshReports}
                                        setAlert={setAlert}
                                        accountHolder={accountHolder}
                                    />
                                );
                            }) : null}
                        {itemType === 'artist' ?
                            item.albums.map((album, i) => {
                                return (
                                    <NestedListItem
                                        key={album.name + i}
                                        item={album}
                                        itemType='album'
                                        setSelectedItems={setSelectedItems}
                                        selectedItems={selectedItems}
                                        refresh={refresh}
                                        setRefresh={setRefresh}
                                        setShowWarningMessage={setShowWarningMessage}
                                        refreshReports={refreshReports}
                                        accountHolder={accountHolder}
                                    />
                                );
                            }) : null}
                        {itemType === 'album' ?
                            item.tracks.map(track => {
                                return (
                                    <NestedListItem
                                        key={track.id}
                                        item={track}
                                        itemType='track'
                                        setSelectedItems={setSelectedItems}
                                        selectedItems={selectedItems}
                                        refresh={refresh}
                                        setRefresh={setRefresh}
                                        setShowWarningMessage={setShowWarningMessage}
                                        refreshReports={refreshReports}
                                        accountHolder={accountHolder}
                                    />
                                );
                            }) : null}

                    </List>
                </Collapse>
                :
                null
            }
        </>
    );
}

const DownloadReportsModal = ({ accountHolder, onClose }) => {
    const styles = useStyles();
    const [reports, setReports] = useState({});
    const [selectedReports, setSelectedReports] = useState({});
    const [refresh, setRefresh] = useState({});
    const [isDownloading, setIsDownloading] = useState(false);
    const [selectUnselectBtn, setSelectUnselectBtn] = useState(false);
    const [showWarningMessage, setShowWarningMessage] = useState(false);
    const [alert, setAlert] = useState('');
    const defaultValue = 'Select reporting company';
    const [selectedReportingCompany, setSelectedReportingCompany] = useState(defaultValue);
    const [reportingCompanies, setReportingCompanies] = useState([]);
    const [filteredReports, setFilteredReports] = useState({});

    function prepareReports(reports) {
        const tempReports = {};
        for (const report of reports) {
            if (tempReports[report.reportingPeriod]) {
                tempReports[report.reportingPeriod].push(report);
            } else {
                tempReports[report.reportingPeriod] = [report];
            }
        }
        return tempReports;
    } 

    const fetchAccountHolderReports = () => {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/report/files?accountHolderId=${accountHolder.id}`)
            .then(fetchData => {
                setReports(prepareReports(fetchData.data));
                setReportingCompanies(fetchData.reportingCompanyCount);
            });
    }

    useEffect(() => {
        fetchAccountHolderReports();
    }, [])

    useEffect(() => {
        if(selectedReportingCompany !== defaultValue){
            const filteredReportstUnorganized = Object.values(reports).flat().filter((report) => report.name === selectedReportingCompany);
            setFilteredReports(prepareReports(filteredReportstUnorganized));

        } else {
            setFilteredReports({});
        }
    }, [selectedReportingCompany]);

    function downloadFiles() {
        setIsDownloading(true);
        download(process.env.REACT_APP_SERVER_HOST + '/api/report/report-files-stream', { reportIds: Object.keys(selectedReports) }, 'zip')
            .then(data => {
                setIsDownloading(false);
            }, err => {
                // setAlert(err.message);
                setIsDownloading(false);
            })
    }

    function checkAll() {
        const reportIds = {};
        for (const key in reports) {
            const periodReports = reports[key];
            for (const report of periodReports) {
                reportIds[report.id] = true;
            }
        }
        setSelectedReports(reportIds);
        setRefresh({});
    }

    function unCheckAll() {
        setSelectedReports({});
        setRefresh({});
    }

    useEffect(() => {
        const selectedReportsLength = Object.keys(selectedReports).length;
        const totalReportsLength = Object.values(reports).reduce((acc, arr) => acc + arr.length, 0);

        if (selectedReportsLength === totalReportsLength && totalReportsLength !== 0) {
            setSelectUnselectBtn(true);
        } else {
            setSelectUnselectBtn(false);
        }
        setRefresh({});
    }, [selectedReports]);

    function onChangeSelectedreportingCompany(e) {
        setSelectedReportingCompany(e.target.value);
    }

    return (
        <div className={clsx(styles.modalLayout, styles.downloadReportContainer)}>
            <div className={styles.downloadReportsHeader}>
                <div className={styles.headingAndFilterWrapper}>
                <div className={styles.deleteMessageWrapper}>
                    <Typography
                        variant='h5'
                        component='div'
                    >
                        Reports
                    </Typography>
                    { showWarningMessage && (
                        <Typography className={styles.deleteWarningMessage}>
                            Deleting this statement all data related to the song will be deleted as well.
                        </Typography>
                    ) }
                    {alert !== '' && (
                        <BrandAlert>{alert}</BrandAlert>
                    )}
                </div>
                {Object.keys(reports).length > 0 && (
                    <div className={styles.filterWrapper}>
                    <Typography className={styles.filterHeading}>Filter by</Typography>
                    <BrandSelect
                        label=""
                        value={selectedReportingCompany}
                        onChangeWithNotify={onChangeSelectedreportingCompany}
                    >
                        <BrandMenuItem value={defaultValue}>
                            {defaultValue}
                        </BrandMenuItem>
                        {reportingCompanies.length > 0 && reportingCompanies.map((rc) => (
                            <BrandMenuItem key={rc.name} value={rc.name}>
                                {rc.name + ` (${rc.reportingCompanyCount})`}
                            </BrandMenuItem>
                        ))}
                    </BrandSelect>
                    </div>
                )}
                </div>
                <div className={styles.selectBtnsContainer}>
                    {
                        selectUnselectBtn ?
                            <TempBrandButton
                                onClick={unCheckAll}
                                className={styles.unselectBtn}
                            >
                                Unselect All
                            </TempBrandButton>
                            :
                            <TempBrandButton
                                onClick={checkAll}
                                disabled={!Object.keys(reports).length}
                            >
                                Select All
                            </TempBrandButton>
                    }
                </div>
            </div>
            <div>
                <List className={styles.list} component="nav">
                    {Object.keys(reports).length ? (
                        <>
                            {Object.keys(Object.keys(filteredReports).length ? filteredReports : reports).map(report => {
                                return (
                                    <NestedListItem
                                        key={report}
                                        item={(Object.keys(filteredReports).length ? filteredReports : reports)[report]}
                                        itemType='period'
                                        setSelectedItems={setSelectedReports}
                                        selectedItems={selectedReports}
                                        refresh={refresh}
                                        setRefresh={setRefresh}
                                        setShowWarningMessage={setShowWarningMessage}
                                        refreshReports={fetchAccountHolderReports}
                                        setAlert={setAlert}
                                        accountHolder={accountHolder}
                                    />
                                );
                            })}
                        </>
                    ) : (
                        <Typography variant='h5' component='div'>
                            No reports were found
                        </Typography>
                    )}
                </List>
            </div>
            <div className={styles.accHolderActions}>
                <TempSecondaryBrandButton
                    size={ButtonSize.SMALL}
                    variant='outlined'
                    onClick={() => onClose()}
                >
                    Cancel
                </TempSecondaryBrandButton>
                <TempBrandButton
                    size={ButtonSize.SMALL}
                    onClick={downloadFiles}
                    disabled={isDownloading || !Object.keys(selectedReports).length}
                    startIcon={isDownloading ? null : <GetAppIcon />}
                >
                    {isDownloading ? <BrandLoaderDots /> : 'Download'}
                </TempBrandButton>
            </div>
        </div>
    );
}

function setTabProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component='div'>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function ManageSongsModal({ accountHolder, onClose, fetchData }) {
    const styles = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={styles.bar}>
            <Typography variant='h5' className={styles.title} component='div'>Manage Songs For {accountHolder.name}</Typography>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Attach/Detach Songs" {...setTabProps(0)} />
                    <Tab label="Reports" {...setTabProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} className={styles.tabPanel}>
                <ManageSongs accountHolder={accountHolder} onClose={() => onClose()} />
            </TabPanel>
            <TabPanel value={value} index={1} className={styles.tabPanel}>
                <DownloadReportsModal
                    accountHolder={accountHolder}
                    onClose={() => {
                        fetchData();
                        onClose();
                    }}
                />
            </TabPanel>
        </div>
    );
}

function ManageNftsModal({ accountHolder, onClose }) {
    const styles = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={styles.bar}>
            <Typography variant='h5' className={clsx(styles.title, styles.nftTitle)} component='div'>Manage NFT</Typography>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Attach/Detach NFT" {...setTabProps(0)} />
                    <Tab label="NFT Metadata" {...setTabProps(1)} />
                    <Tab label="Manage Ownership" {...setTabProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} className={styles.tabPanel}>
                <AttachAndDetachNfts
                    accountHolder={accountHolder}
                    onClose={() => {
                        onClose();
                    }}
                />
            </TabPanel>
            <TabPanel value={value} index={1} className={styles.tabPanel}>
                <NftMetadata
                    accountHolder={accountHolder}
                    onClose={() => {
                        onClose();
                    }}
                />
            </TabPanel>
            <TabPanel value={value} index={2} className={styles.tabPanel}>
                <ManageOwnership
                    accountHolder={accountHolder}
                    onClose={() => {
                        onClose();
                    }}
                />
            </TabPanel>
        </div>
    );
}

const ManageOwnership = ({ accountHolder, onClose }) => {
    const styles = useStyles()
    const [nfts, setNfts] = useState([])
    const [search, setSearch] = useState('')
    const [searchFilter, setFilterSearch] = useState('');
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(25)
    const [page, setPage] = useState(0);
    const [rowsPerPageOptions] = useState([25, 50, 100]);
    const [rowCounts, setRowCounts] = useState(0);
    const [changedNfts, setChangedNfts] = useState([]);
    const [disabledSave, setDisabledSave] = useState(false);
    const [invalidInputs, setInvalidInputs] = useState({});
    const [isSaving, setSaving] = useState(false);
    const [changedNftsOriginalData, setChangedNftsOriginalData] = useState({});
    const [alert, setAlert] = useState('');
    const [showInputPrimary, setShowInputPrimary] = useState(false);
    const [showInputSecondary, setShowInputSecondary] = useState(false);
    const [finishedSavingAlert, setFinishedSaving] = useState('');
    const [savedSuccessfully, setSavedSuccessfully] = useState(false);

    const indexNameForPrimary = 'p';
    const indexNameForSecondary = 's';

    const props = {
        nftAccountHolders: nfts,
        setInvalidInputs,
        setDisabledSave
    };

    const debounceMemo = useMemo(
        () => debounce(
            (event) => {
                setSearch(event.target.value);
            }, (event) => {
                setFilterSearch(event.target.value);
            }, 500),
        [],
    );

    useEffect(() => {
        if (finishedSavingAlert) {
            setTimeout(() => {
                setFinishedSaving('');
            }, "4000")
        }
    }, [finishedSavingAlert])

    useEffect(() => {
        setAlert('');
    }, [nfts, search, searchFilter, offset, limit, page, rowCounts, changedNfts, invalidInputs, changedNftsOriginalData])

    useEffect(() => {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/nft/nft-ownerships?accountHolder=${accountHolder.id}&offset=${offset}&limit=${limit}&filter=${searchFilter
            }`)
            .then(data => {
                if (changedNfts.length) {
                    for (const changedNft of changedNfts) {
                        const changedNftIndex = data.items.findIndex(x => x.id === changedNft.id);
                        if (changedNftIndex >= 0) {
                            data.items[changedNftIndex] = changedNft;
                        }
                    }
                }

                setNfts(data.items);
                setRowCounts(data.total);
            })
    }, [searchFilter, page, offset, limit])

    useEffect(() => {
        setOffset(page * limit);
    }, [page])

    const onChangeInput = (e, nft, index, isPrimary) => {
        let tempNfts;
        const target = e.target.value;

        if (!changedNftsOriginalData[nft.id]) {
            setChangedNftsOriginalData(changedNftsOriginalData => (changedNftsOriginalData[nft.id] = JSON.parse(JSON.stringify(nft)), { ...changedNftsOriginalData }));
        }

        const parsedTargetValue = target ? target : null;

        if ((changedNftsOriginalData[nft.id].primaryOwnership === parsedTargetValue && isPrimary) || (changedNftsOriginalData[nft.id].secondaryOwnership === parsedTargetValue && !isPrimary)) {
            const tempChangedNfts = changedNfts.filter(x => x.id !== nft.id);
            setChangedNfts([...tempChangedNfts]);
        } else {
            const foundChangedIndex = changedNfts.findIndex(x => x.id === nft.id);
            if (foundChangedIndex >= 0) {
                changedNfts[foundChangedIndex] = nfts[index];
                setChangedNfts([...changedNfts]);
            } else {
                setChangedNfts([...changedNfts, nft]);
            }
        }

        if (isPrimary) {
            tempNfts = validateSingleInput(props, indexNameForPrimary, index, target, isPrimary);
        } else {
            tempNfts = validateSingleInput(props, indexNameForSecondary, index, target, isPrimary);
        }
        setNfts([...tempNfts]);
    }

    const onClickSave = () => {
        setSaving(true);
        putData(process.env.REACT_APP_SERVER_HOST + `/api/nft/nft-ownerships`, { accountHolderId: accountHolder.id, changedNfts: changedNfts })
            .then(data => {
                setSaving(false);
                setChangedNftsOriginalData({});
                setInvalidInputs({});
                setChangedNfts([]);
                setSavedSuccessfully(true);
                setFinishedSaving('Saved successfully');
            })
            .catch(error => {
                setAlert(error.message)
                setSaving(false);
            })
    }

    useEffect(() => {
        setPage(0);
    }, [searchFilter])

    useEffect(() => {
        const hasInvalidInputs = Object.keys(invalidInputs).length > 0;
        const hasChangedNfts = changedNfts.length === 0;

        if (hasInvalidInputs || hasChangedNfts) {
            setDisabledSave(true);
        } else {
            setDisabledSave(false);
        }
    }, [invalidInputs, changedNfts])

    const bulkInputHandler = (e, isPrimary) => {
        const target = e.target.value
        let tempNfts;
        if (isPrimary) {
            nfts.map((el, index) => {
                tempNfts = validateSingleInput(props, indexNameForPrimary, index, target, isPrimary);
            })
        } else {
            nfts.map((el, index) => {
                tempNfts = validateSingleInput(props, indexNameForSecondary, index, target, isPrimary);
            })
        }

        setNfts([...tempNfts]);
        setChangedNfts([...tempNfts]);
    }

    return (
        <div className={styles.manageOwnershipContainer}>
            <div className={styles.manageOwnershipSearch}>
                <BrandSearchInput className={styles.manageOwnershipSearch} value={search} onChange={debounceMemo} />
            </div>
            {alert ? <BrandAlert>{alert}</BrandAlert> : null}
            <List className={styles.list}>
                <div className={styles.ownershipTitles}>
                    <div style={{ width: '63%', textAlign: 'start' }}>Attached NFT</div>
                    <div className={styles.primaryTitle}> Primary Ownership </div>
                    <div> Secondary Ownership </div>
                </div>
                <ListItem>
                    <ListItemText primary={''} />
                    <div className={styles.manageOwnershipSalesInput}>
                        <div>
                            {
                                showInputPrimary ?
                                    <BrandInput
                                        classes={{ input: styles.manageOwnershipInputs }}
                                        onChange={(e) => bulkInputHandler(e, true)}
                                    />
                                    :
                                    <div
                                        className={styles.bulkEditButton}
                                        onClick={(e) => setShowInputPrimary(true)}
                                    >
                                        < EditIcon />
                                        Bulk Edit
                                    </div>
                            }
                        </div>
                        <div>
                            {
                                showInputSecondary ?
                                    <BrandInput
                                        classes={{ input: styles.manageOwnershipInputs }}
                                        onChange={e => bulkInputHandler(e,false)}
                                    />
                                    :
                                    <div
                                        className={styles.bulkEditButton}
                                        onClick={(e) => setShowInputSecondary(true)}
                                    >
                                        < EditIcon />
                                        Bulk Edit
                                    </div>
                            }
                        </div>
                    </div>
                </ListItem>
                {
                    nfts.map((nft, index) => {
                        const ownerships = JSON.parse(nft.ownerships);
                        return (
                            <ListItem key={nft.id}>
                                <ListItemAvatar>
                                    {nft.coverArt ?
                                        <Avatar
                                            alt={`Avatar of ${nft.title}`}
                                            src={nft.coverArt}
                                        />
                                        :
                                        <AlbumIcon fontSize='large' />
                                    }
                                </ListItemAvatar>
                                <ListItemText primary={nft.title} />
                                <ListItemAvatar className={styles.informationButton}>
                                    <InfoOutlinedIcon fontSize='large' />
                                    <span className='tooltip'>
                                        Total Assigned Ownerships:<br />
                                        Primary: {ownerships.primaryOwnership}%<br />
                                        Secondary: {ownerships.secondaryOwnership}%<br />
                                    </span>
                                </ListItemAvatar>
                                <div className={styles.manageOwnershipSalesInput}>
                                    <div>
                                        <BrandInput
                                            InputProps={{
                                                endAdornment: (
                                                    nft.primaryOwnership > 0 ?
                                                        <InputAdornment position="start">%</InputAdornment>
                                                        :
                                                        null
                                                )
                                            }}
                                            value={nft.primaryOwnership}
                                            classes={{ input: styles.manageOwnershipInputs }}
                                            onChange={(e) => onChangeInput(e, nft, index, true)}
                                        />
                                        <div className={styles.ownershipAlert}>
                                            {invalidInputs[`p${index}`] ? 'Ownership must be a positive number' : ''}
                                        </div>
                                    </div>
                                    <div className={styles.secondaryOwnershipAlertMessage}>
                                        <BrandInput
                                            InputProps={{
                                                endAdornment: (
                                                    nft.secondaryOwnership > 0 ?
                                                        <InputAdornment position="start">%</InputAdornment>
                                                        :
                                                        null
                                                )
                                            }}
                                            value={nft.secondaryOwnership}
                                            classes={{ input: styles.manageOwnershipInputs }}
                                            onChange={(e) => onChangeInput(e, nft, index, false)}
                                        />
                                        <div className={styles.ownershipAlert}>
                                            {invalidInputs[`s${index}`] ? 'Ownership must be a positive number' : ''}
                                        </div>
                                    </div>

                                </div>
                            </ListItem>
                        )
                    })
                }
            </List>

            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                count={rowCounts}
                rowsPerPage={limit}
                page={page}
                component="div"
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => {
                    setLimit(parseInt(event.target.value, 10));
                    setPage(0);
                }}
                ActionsComponent={TablePaginationActions}
            />

            <div className={styles.accHolderActions}>
                <TempSecondaryBrandButton
                    size={ButtonSize.SMALL}
                    variant='outlined'
                    onClick={() => onClose()}
                >
                    Cancel
                </TempSecondaryBrandButton>
                <TempBrandButton
                    size={ButtonSize.SMALL}
                    disabled={isSaving || disabledSave}
                    onClick={onClickSave}
                >
                    {isSaving ? <BrandLoaderDots /> : 'Save'}
                </TempBrandButton>
            </div>
            <div className={styles.saveMessage} >
                {finishedSavingAlert ? <BrandAlert success={savedSuccessfully}>{finishedSavingAlert}</BrandAlert> : null}
            </div>
        </div>
    )

}

const NftMetadata = ({ accountHolder, onClose }) => {
    const styles = useStyles();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [exporting, setExporting] = useState(false);
    const [hasUploads, setHasUploads] = useState(false);
    const [isProcessingFile, setIsProcessingFile] = useState(false);

    const handleDrop = (files) => {
        const filesToAdd = [];
        if (Array.isArray(files)) {
            for (const file of files) {
                if (!uploadedFiles.find(x => file.name === x.name)) {
                    filesToAdd.push(file);
                }
            }
            const tempFilesToAdd = [...uploadedFiles, ...filesToAdd].slice(0, 1);
            setUploadedFiles(tempFilesToAdd)
        } else if (!uploadedFiles.find(x => files.name === x.name)) {
            const tempFilesToAdd = [...uploadedFiles, files].slice(0, 1);
            setUploadedFiles(tempFilesToAdd);
        }
    };

    useEffect(() => {
        setHasUploads(uploadedFiles.length == true);
    }, [uploadedFiles])

    async function exportCsv() {
        setExporting(true);
        getData(process.env.REACT_APP_SERVER_HOST + `/api/nft/attached-nfts-to-account-holder?accountHolder=${accountHolder.id}`)
            .then(data => {
                const asJsonData = JSON.stringify(data.items);
                const asCsvData = Papa.unparse(asJsonData);
                const csvFile = new File([asCsvData], `${accountHolder.name}.csv`, {
                    type: "text/plain",
                });
                const dateStamp = new Date().toISOString().replace(/[-:T]/g, '').split('.')[0];
                const url = window.URL.createObjectURL(csvFile);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${accountHolder.name}_${dateStamp}.csv`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                setExporting(false);
            })
    }

    async function onClickSave() {
        parseFile(uploadedFiles[0])
    }

    function parseFile(file) {
        Papa.parse(file, {
            header: true,
            worker: true,
            complete: function (results) {
                const mappingRowFill = {};
                results.meta.fields.forEach(x => mappingRowFill[x] = 1);
                let resultsData = [];
                const resultsDataKeys = Object.keys(results.data[results.data.length - 1]);

                if (resultsDataKeys.length < results.meta.fields.length || (resultsDataKeys.length === 1 && results.data[0][resultsDataKeys[0]] === '')) {
                    resultsData = results.data.slice(0, -1);
                    //for some reason last row of csv is always {key:""}, so i'm deleting it
                } else {
                    resultsData = results.data;
                }
                putData(process.env.REACT_APP_SERVER_HOST + `/api/nft/nft-metadata`, { metadata: resultsData })
                    .then(data => {
                        setIsProcessingFile(false);
                    }, error => {
                        console.error(error);
                    })
            }
        });
    }


    return (
        <div className={styles.nftMetadataContainer}>
            <div className={styles.nftExportAndImportContainer}>
                <div className={styles.exportNftCsvContainer}>
                    <Typography
                        variant="h6"
                        style={{ paddingBottom: 20 }}
                    >
                        Export a CSV file with all attached NFT.
                    </Typography>
                    <TempBrandButton disabled={exporting} onClick={exportCsv}>Export CSV</TempBrandButton>
                </div>
                <div className={styles.exportNftCsvContainer}>
                    <Typography
                        variant="h6"
                        style={{ paddingBottom: 20 }}
                    >
                        Upload a CSV file to update the metadata for all attached NFT
                    </Typography>
                    <div className={styles.contentContainer}>

                        {!hasUploads ?
                            <div className={styles.uploadContainer}>
                                <div className={styles.upload}>
                                    <DropZone
                                        handleDrop={handleDrop}
                                        acceptedFormats={{'text/csv': ['.csv']}}
                                    />
                                </div>
                            </div>
                            :
                            <div className={styles.uploadedFilesContainer}>
                                <div className={styles.uploadedFiles}>
                                    <div className={styles.uploadedFilesHeader}>
                                        <div className={styles.uploadedFilesTitle}>Uploaded File</div>
                                    </div>
                                    <List>
                                        {uploadedFiles.map(file => {
                                            return (
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <DescriptionOutlinedIcon style={{ color: '#FFFFFF8A' }} />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={file.name}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <IconButton edge="end" aria-label="delete">
                                                            <DeleteOutlineOutlinedIcon style={{ color: '#FFFFFF8A' }}
                                                                onClick={() => {
                                                                    setUploadedFiles(uploadedFiles.filter(x => x !== file));
                                                                }} />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
            <div className={styles.accHolderActions}>
                <TempSecondaryBrandButton
                    variant='outlined'
                    onClick={() => onClose()}
                >
                    Cancel
                </TempSecondaryBrandButton>
                <TempBrandButton
                    onClick={onClickSave}
                    disabled={isProcessingFile}
                >
                    Save
                </TempBrandButton>
            </div>
        </div>
    );
}

const AttachAndDetachNfts = ({ accountHolder, onClose }) => {
    const styles = useStyles();
    const [attachedNftsFromDetachedNft, setAttachedNftsFromDetachedNft] = useState([]);
    const [detachedNftsFromAttachedNfts, setDetachedNftsFromAttachedNfts] = useState([]);
    const [originalNftData, setOriginalNftData] = useState('')
    const [disabledSave, setDisabledSave] = useState(false)
    const [finishedSavingAlert, setFinishedSaving] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [savedSuccessfully, setSavedSuccessfully] = useState(false);
    const [clickDetach, setClickDetach] = useState(false);
    const [finishedDetachingAlert, setFinishedDetaching] = useState('');
    const [isDetaching, setIsDetaching] = useState(false);
    const [detachedSuccessfully, setDetachedSuccessfully] = useState(false);

    const [total, setTotal] = useState(0);

    async function onSave() {
        try {
            setIsSaving(true)

            if (detachedNftsFromAttachedNfts.length) {
                await postData(process.env.REACT_APP_SERVER_HOST + `/api/nft/detach-nfts`,
                    { accountHolderId: accountHolder.id, nftsToDetach: detachedNftsFromAttachedNfts }
                )
            }
            if (attachedNftsFromDetachedNft.length) {
                await postData(process.env.REACT_APP_SERVER_HOST + `/api/nft/attach-nfts`,
                    { accountHolderId: accountHolder.id, nftsToAttach: attachedNftsFromDetachedNft }
                )
            }
            if(detachedNftsFromAttachedNfts.length || attachedNftsFromDetachedNft.length ){
                const { total } = await getData(process.env.REACT_APP_SERVER_HOST + `/api/nft/account-holder-nfts?accountHolder=${accountHolder.id}&offset=0&limit=10&filter=`);
                setTotal(total);
            }

        } catch (error) {
            setFinishedSaving('Failed to save, please try again');
            console.error(error);
        }

        setIsSaving(false)
        setSavedSuccessfully(true);
        setFinishedSaving('Saved successfully');

        setOriginalNftData(JSON.stringify(attachedNftsFromDetachedNft))
        setDisabledSave(true)
    }

    useEffect(() => {
        if (finishedSavingAlert) {
            setTimeout(() => {
                setFinishedSaving('');
            }, "4000")
        }
    }, [finishedSavingAlert])

    useEffect(() => {
        const parsedData = JSON.stringify(attachedNftsFromDetachedNft)

        parsedData === originalNftData ? setDisabledSave(true) : setDisabledSave(false)
    }, [attachedNftsFromDetachedNft])

    return (
        <>
            <div className={styles.tabPanelContainerForAttachAndDetachNftsContainer}>
                <div className={styles.attachAndDetachNftsContainer}>
                    <AttachNftsModal
                        accountHolder={accountHolder}
                        attachedNftsFromDetachedNft={attachedNftsFromDetachedNft}
                        setAttachedNftsFromDetachedNft={setAttachedNftsFromDetachedNft}
                        detachedNftsFromAttachedNfts={detachedNftsFromAttachedNfts}
                        setDetachedNftsFromAttachedNfts={setDetachedNftsFromAttachedNfts}
                        clickDetach={clickDetach}
                        setClickDetach={setClickDetach}
                        setFinishedDetaching={setFinishedDetaching}
                        setIsDetaching={setIsDetaching}
                        setDetachedSuccessfully={setDetachedSuccessfully}
                    />
                </div>
                <div className={styles.attachAndDetachNftsContainer}>
                    <DetachNFTsModal
                        accountHolder={accountHolder}
                        attachedNftsFromDetachedNft={attachedNftsFromDetachedNft}
                        setAttachedNftsFromDetachedNft={setAttachedNftsFromDetachedNft}
                        detachedNftsFromAttachedNfts={detachedNftsFromAttachedNfts}
                        setDetachedNftsFromAttachedNfts={setDetachedNftsFromAttachedNfts}
                        setOriginalNftData={setOriginalNftData}
                        finishedDetachingAlert={finishedDetachingAlert}
                        setFinishedDetaching={setFinishedDetaching}
                        isDetaching={isDetaching}
                        setIsDetaching={setIsDetaching}
                        detachedSuccessfully={detachedSuccessfully}
                        setDetachedSuccessfully={setDetachedSuccessfully}
                        setClickDetach={setClickDetach}
                        setTotal={setTotal}
                    />
                </div>
            </div>
            {total > 0 &&
                <div className={styles.totalAttachedCount}>Total attached {total}</div>
            }
            <div className={styles.accHolderActions}>
                <TempSecondaryBrandButton
                    size={ButtonSize.SMALL}
                    variant='outlined'
                    onClick={() => onClose()}
                >
                    Cancel
                </TempSecondaryBrandButton>
                <div className={styles.attachSongButtonAndAlertContainer}>
                    <TempBrandButton
                        size={ButtonSize.SMALL}
                        disabled={disabledSave}
                        onClick={onSave}
                    >
                        {isSaving ?
                            <div className={styles.uploading}>
                                <BrandLoader color='white' width={20} height={20} />
                                <div>Saving</div>
                            </div>
                            :
                            'Save'
                        }
                    </TempBrandButton>
                </div>
            </div>
            <div className={styles.saveMessage} >
                {finishedSavingAlert ? <BrandAlert success={savedSuccessfully}>{finishedSavingAlert}</BrandAlert> : null}
            </div>
        </>
    );
}

const EditAccountHolderName = ({ rowData, refresh, setPage }) => {
    const [error, setError] = useState('');
    const [state] = useStoreContext();
    const styles = useStyles();
    const [showIcon, setShowIcon] = useState(false);
    const accountHolderName = useState(rowData.name)
    const walletAddress = useState(rowData.walletAddress || '')
    const ipiNumber = useState(rowData.ipiNumber || null)
    const [disabled, setDisabled] = useState(true)
    const [anchorEl, setAnchorEl] = useState(null);
    const [errorUploadLogo, setErrorUploadLogo] = useState('');
    const [formDataLogo,setFormDataLogo] = useState(null);
    const [previewLogo,setPreviewLogo] = useState(rowData.logoURL ? {URL:rowData.logoURL,name:rowData.fileName} : null);
    const openNameInputsPopover = Boolean(anchorEl);

    const idNameInputs = openNameInputsPopover ? 'nameInputs-popover' : undefined;

    const showInputHandler = (e) => {
        setShowIcon(false);
        setAnchorEl(e.currentTarget);
    }
    const handleClosePopover = () => {
        setPreviewLogo(rowData.logoURL ? {URL:rowData.logoURL,name:rowData.fileName} : null);
        setAnchorEl(null);
        setShowIcon(false);
        setError('');
        accountHolderName[1](rowData.name)
        setShowIcon(false);
    };

    useEffect(() => {
        isNaN(ipiNumber[0]) ? setDisabled(true) : setDisabled(false);

        if (accountHolderName[0].length === MAX_LENGTH_FOR_ACCOUNT_HOLDER) {
            setError(`Name cannot be longer than ${MAX_LENGTH_FOR_ACCOUNT_HOLDER} characters`);
        } else {
            setError('');
        }

        if(ipiNumber[0]?.length > MAX_LENGTH_FOR_IPI_NUMBER || isNaN(ipiNumber[0])){
            setError('IPI number invalid format or length');
        } else {
            setError('');
        }
    }, [accountHolderName[0], ipiNumber[0]]);

    const onSubmit = async (e) => {
        e.preventDefault();

        const requestData = {
            accountHolderId: rowData.id,
            newName: accountHolderName[0],
            walletAddress: walletAddress[0],
            ipiNumber:ipiNumber[0]
        };

        try {
            await putData(process.env.REACT_APP_SERVER_HOST + `/api/account-holder/edit-account-holder`, requestData);

            if(formDataLogo){
                await postFormData(process.env.REACT_APP_SERVER_HOST + `/api/account-holder/upload-account-holder-logo`, formDataLogo);
            }
            if(!previewLogo && rowData.logoURL){
                await deleteData('/api/account-holder/delete-account-holder-logo',{accountHolderId:rowData.id});
            }

            setShowIcon(false);
            rowData.name = accountHolderName[0]
            setAnchorEl(null);
            setError('');
            refresh();

        } catch (error) {
            setError(error.message);
        }
    }

    useEffect(() => {
        const error = dataValidators.isRequired(accountHolderName[0])
        error.length > 0 ? setDisabled(true) : setDisabled(false)
    }, [accountHolderName[0]])

    const handleDeleteLogo = () =>{
        setPreviewLogo(null);
    }

    const handleUpload = (e) =>{
        const file = e.target.files[0];

        if(!accountHolderConstants.logo.VALIDATE_FILE_TYPES.find(type => type === file.type)){
            setErrorUploadLogo('File must be in JPG/PNG/SVG format');
            return 
        }else {
            setErrorUploadLogo('');
        }

        setPreviewLogo({URL:URL.createObjectURL(file),name:file.name});
        
        const form = new FormData();
        form.append('file',file);
        form.append('accountHolderId',rowData.id);

        setFormDataLogo(form)
    }
    

    return (
        <div
            className={styles.editAccountHolderNameContainer}
        >
            <div className={styles.editAccountHolderNameButton}>

                    <div className={styles.editAccountHolderIconBlock}
                        onMouseOver={() => setShowIcon(true)}
                        onMouseLeave={() => setShowIcon(false)}>
                        <span>{rowData?.name}</span>
                        <EditIcon
                            onClick={showInputHandler}
                            color='primary'
                            className={showIcon ? '' : styles.hiddenIcon}
                        />
                    </div>
                <Popover
                        id={idNameInputs}
                        open={openNameInputsPopover}
                        anchorEl={anchorEl}
                        onClose={handleClosePopover}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <div className={styles.inputsSection}>
                            <form onSubmit={onSubmit}>
                                <div className={styles.inputsContainer}>
                                    <Typography
                                        variant="h6"
                                        component="div"
                                    >
                                        Edit Account Holder
                                    </Typography>
                                    {error && <BrandAlert>{error}</BrandAlert>}
                                    <BrandInput
                                        label='Account Holder Name'
                                        $value={accountHolderName}
                                        maxLength={MAX_LENGTH_FOR_ACCOUNT_HOLDER}
                                        validator={dataValidators.isRequired}
                                        required
                                    />
                                    {
                                        state.navType !== "Music" ?
                                            <BrandInput
                                                label='Wallet Address'
                                                $value={walletAddress}
                                            />
                                            :
                                            <BrandInput
                                                label='IPI Number(Optional)'
                                                $value={ipiNumber}
                                                maxLength={MAX_LENGTH_FOR_IPI_NUMBER}
                                            />
                                    }
                                    <UploadLogoAccountHolder styles={styles} previewLogo={previewLogo} handleUpload={handleUpload} handleDeleteLogo={handleDeleteLogo} errorUploadLogo={errorUploadLogo} />

                                </div>
                                <div className={styles.accHolderActions}>
                                    <TempSecondaryBrandButton
                                        size={ButtonSize.SMALL}
                                        variant='outlined'
                                        onClick={handleClosePopover}
                                    >
                                        Cancel
                                    </TempSecondaryBrandButton>
                                    <TempBrandButton
                                        size={ButtonSize.SMALL}
                                        type="submit"
                                        disabled={disabled}
                                    >
                                        Save
                                    </TempBrandButton>
                                </div>
                            </form>
                        </div>
                </Popover>
            </div>
        </div>
    );
}

const ManageAccountHolders = ({ accountHolder }) => {
    const styles = useStyles();
    const [state] = useStoreContext();
    const [displayAddAccountHolderModal, setDisplayAddAccountHolderModal] = useState(false);
    const [selectedAccountHolder, setSelectedAccountHolder] = useState({});
    const [displayDisableModal, setDisplayDisableModal] = useState(false);

    const [search, setSearch] = useState('');
    const searchQuery = useDebounce(search, 500);

    const [error, setError] = useState('');
    const [checked, setChecked] = useState(false);

    const [notChecked, setNotChecked] = useState(false);

    const [accHolders, setAccHolders] = useState([]);
    const [offset, setOffset] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(MANAGE_ACCOUNT_HOLDERS_DEFAULT_ROWS_PER_PAGE);
    const [sortBy, setSortBy] = useState('id');
    const [sortType, setSortType] = useState('ASC');
    const [rowCounts, setRowCounts] = useState(0);
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState(MANAGE_ACCOUNT_HOLDERS_ROWS_PER_PAGE_OPTIONS);
    const [page, setPage] = useState(0);
    const [selectedRowDataForDelete, setSelectedRowDataForDelete] = useState({});
    const [displayDeleteAccHolderModal, setDisplayDeleteAccHolderModal] = useState(false);
    const [displayManageNftsModal, setDisplayManageNftsModal] = useState(false);
    const [displayManageSongsModal, setDisplayManageSongsModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [headCells, setHeadCells] = useState([
        {
            id: 'name',
            label: 'Account Holder',
            CellRender: ({ rowData }) => {
                return (
                    <EditAccountHolderName
                        rowData={rowData}
                        refresh={() => fetchData()}
                    />
                );
            }
        },
        {
            id: 'disabled',
            label: 'Status',
            CellRender: ({ rowData }) => {
                return (
                    <SecondaryBrandButton
                        variant='text'
                        key={rowData.id}
                        startIcon={rowData.disabled ? <CheckBoxOutlineBlankIcon /> : <CheckBoxIcon />}
                        onClick={e => {
                            e.stopPropagation();
                            setDisplayDisableModal(true);
                            setSelectedAccountHolder(rowData);
                        }}
                    >
                        {rowData.disabled ?
                            'Disabled'
                            :
                            'Enabled'
                        }
                    </SecondaryBrandButton>
                );
            }
        },
        {
            id: 'logo',
            label: 'Logo',
            CellRender: ({ rowData }) => {
                return (
                    <>
                        {rowData.logoURL ? 
                        <img src={rowData.logoURL} alt='logo' style={{width:'40px',height:'40px'}}/> 
                            : 
                        <span style={{marginLeft:'10px'}}>N/A</span>}
                    </>
                );
            }
        },
        {
            id: 'manageSongs',
            label: '',
            hideHeadLabel: true,
            CellRender: ({ rowData }) => {
                return (
                    <IconButton
                        style={{ color: 'white', fontSize: '0.875rem' }}
                        onClick={() => {
                            setDisplayManageSongsModal(true);
                            setSelectedAccountHolder(rowData);
                        }}>
                        <LibraryMusicIcon fontSize='small' />
                        Manage Songs
                    </IconButton>
                )
            }
        },
        // {
        //     id: 'manageNfts',
        //     label: '',
        //     hideHeadLabel: true,
        //     CellRender: ({ rowData }) => {
        //         return <IconButton
        //             style={{ color: 'white', fontSize: '0.875rem' }}
        //             onClick={() => {
        //                 setDisplayManageNftsModal(true);
        //                 setSelectedAccountHolder(rowData);
        //             }}>
        //             {/* <ReceiptIcon fontSize='small' />Manage NFTs */}
        //             <EthIcon header={true} />&nbsp;Manage NFTs
        //         </IconButton>
        //     }
        // },
        {
            id: 'delete',
            label: '',
            hideHeadLabel: true,
            CellRender: ({ rowData }) => {
                return (
                    <IconButton
                        style={{ color: 'white', fontSize: '0.875rem' }}
                        onClick={() => {
                            setSelectedRowDataForDelete(rowData);
                            setDisplayDeleteAccHolderModal(true);
                        }}>
                        <DeleteOutlineOutlinedIcon fontSize='small' />
                        Delete
                    </IconButton>
                )
            }
        }
    ]);

    // useEffect(() => {
    //     tableRowsPerClientViewHeight(setRowsPerPage, setRowsPerPageOptions, rowsPerPageOptions, 200)
    // }, []);

    useEffect(() => {
        const tempHeadCells = [...headCells];
        if (state.navType === 'Music') {
            const manageSongsHeadCell = tempHeadCells.find(headCell => headCell.id === 'manageNfts');
            if (manageSongsHeadCell) {
                manageSongsHeadCell.id = 'manageSongs';
                manageSongsHeadCell['CellRender'] = ({ rowData }) => {
                    return (
                        <IconButton
                            style={{ color: 'white', fontSize: '0.875rem' }}
                            onClick={() => {
                                setDisplayManageSongsModal(true);
                                setSelectedAccountHolder(rowData);
                            }}>
                            <LibraryMusicIcon fontSize='small' />
                            Manage Songs
                        </IconButton>
                    )
                }
                setHeadCells([...tempHeadCells]);
            }
        } else if (state.navType === 'Blockchain') {
            const manageNftsHeadCell = tempHeadCells.find(headCell => headCell.id === 'manageSongs');
            if (manageNftsHeadCell) {
                manageNftsHeadCell.id = 'manageNfts';
                manageNftsHeadCell['CellRender'] = ({ rowData }) => {
                    return (
                        <IconButton
                            style={{ color: 'white', fontSize: '0.875rem' }}
                            onClick={() => {
                                setDisplayManageNftsModal(true);
                                setSelectedAccountHolder(rowData);
                            }}>
                            {/* <ReceiptIcon fontSize='small' />Manage NFTs */}
                            <EthIcon header={true} />
                            &nbsp;Manage NFT
                        </IconButton>
                    )
                }
                setHeadCells([...tempHeadCells]);
            }
        }
    }, [state]);

    const fetchData = () => {
        const currentParameters = getCurrentFetchParameters();
        getData(process.env.REACT_APP_SERVER_HOST + `/api/account-holder/?offset=${currentParameters.offset}&limit=${currentParameters.rowsPerPage}&sort=${currentParameters.sortBy}&type=${currentParameters.sortType}&filter=${currentParameters.searchQuery}`)
            .then(data => {
                setAccHolders(data.items);
                setRowCounts(data.total)
                setLoading(false)
            })
    };

    const getCurrentFetchParameters = () => {
        const currentParameters = {
            offset: offset,
            rowsPerPage: rowsPerPage,
            sortBy: sortBy,
            sortType: sortType,
            searchQuery: searchQuery
        }

        setOffset(prev => {
            currentParameters.offset = prev;
            return prev
        })
        setRowsPerPage(prev => {
            currentParameters.rowsPerPage = prev;
            return prev
        })
        setSortBy(prev => {
            currentParameters.sortBy = prev;
            return prev
        })
        setSortType(prev => {
            currentParameters.sortType = prev;
            return prev
        })
        setSearch(prev =>{
            currentParameters.searchQuery = prev;
             return prev
        })
        return currentParameters
    }

    useEffect(() => {
        setPage(0);
    }, [searchQuery]);

    useEffect(() => {
        if (rowsPerPage !== 0) {
            fetchData();
        }
    }, [offset, sortType, sortBy, searchQuery, rowsPerPage]);

    useEffect(() => {
        setOffset(page * rowsPerPage);
    }, [page])

    const onDeleteAccountHolder = () => {
        setIsLoadingDelete(true);

        if (!error || (error && checked)) {
            deleteData(process.env.REACT_APP_SERVER_HOST + '/api/account-holder/delete', { id: selectedRowDataForDelete.id, confirmation: checked })
                .then(data => {
                    setDisplayDeleteAccHolderModal(false);
                    fetchData();
                    setError('');
                    setChecked(false);
                })
                .catch((error) => {
                    setError(error.message);
                })
                .finally(() => setIsLoadingDelete(false));
        } else {
            setNotChecked(true);
            setIsLoadingDelete(false);
        }
    };

    return (
        <div className={styles.container}>
            <BrandModal
                open={displayManageNftsModal}
                onClose={() => setDisplayManageNftsModal(false)}
            >
                <ManageNftsModal
                    accountHolder={selectedAccountHolder}
                    onClose={() => setDisplayManageNftsModal(false)}
                    fetchData={fetchData}
                />
            </BrandModal>
            <BrandModal
                open={displayManageSongsModal}
                onClose={() => setDisplayManageSongsModal(false)}
            >
                <ManageSongsModal
                    accountHolder={selectedAccountHolder}
                    onClose={() => setDisplayManageSongsModal(false)}
                    fetchData={fetchData}
                />
            </BrandModal>
            <BrandDeleteModal
                open={displayDeleteAccHolderModal}
                onClose={() => {
                    setDisplayDeleteAccHolderModal(false);
                    setError('');
                    setChecked(false);
                }}
                onDelete={() => onDeleteAccountHolder()}
                loading={isLoadingDelete}
            >
                <Typography
                    variant="h5"
                    style={{ paddingBottom: 10 }}
                >
                    Delete Account Holder
                </Typography>
                {error && (
                    <Typography
                        variant='h5'
                        className={styles.error}
                    >{error}</Typography>
                )}
                <div className={styles.deleteText}>
                    Are you sure you want to delete "{selectedRowDataForDelete.name}"?
                </div>
                {error && (
                    <TempBrandButton
                        variant='text'
                        startIcon={checked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                        onClick={() => setChecked(!checked)}
                    >Do you want to delete it?
                    </TempBrandButton>)}
            </BrandDeleteModal>
            <BrandModal
                open={displayDisableModal}
                onClose={() => setDisplayDisableModal(false)}
            >
                <Typography variant='h5' component='div' >Change status</Typography>
                <div className={styles.saveMsg}>
                    {selectedAccountHolder.disabled ?
                        `Are you sure you want to enable ${selectedAccountHolder.name}`
                        :
                        `Are you sure you want to disable ${selectedAccountHolder.name}`
                    }
                </div>
                <div className={styles.actions}>
                    <TempSecondaryBrandButton
                        variant='outlined'
                        onClick={() => setDisplayDisableModal(false)}>
                            No
                    </TempSecondaryBrandButton>
                    <TempBrandButton 
                        onClick={() => {
                            putData(process.env.REACT_APP_SERVER_HOST + `/api/account-holder/disable`, {
                                id: selectedAccountHolder.id,
                                disable: !selectedAccountHolder.disabled
                            }).then(data => {
                                if (data.success) {
                                    fetchData();
                                    setDisplayDisableModal(false);
                                }
                            })
                        }}>
                        Yes
                    </TempBrandButton>
                </div>
            </BrandModal>
            <BrandModal
                open={displayAddAccountHolderModal}
                onClose={() => setDisplayAddAccountHolderModal(false)}
            >
                <AddAccountHolder onClose={() => {
                    fetchData();
                    setDisplayAddAccountHolderModal(false)
                }} />
            </BrandModal>
            <Typography
                variant="h6"
                style={{ paddingBottom: 20 }}
                component='div'
                className={styles.accountHolderMainTitle}
            >
                Account Holders
            </Typography>
            <div className={styles.toolbar}>
                <div className={styles.leftSide}>
                    <BrandSearchInput
                        placeholder='Search for account holder'
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                        classes={{ input: styles.input }}
                    />
                    <TempBrandButton
                        capitalize={true}
                        startIcon={<PersonAdd />}
                        onClick={() => setDisplayAddAccountHolderModal(true)}
                    >
                        Add Account Holder
                    </TempBrandButton>
                </div>
            </div>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <BrandTable
                        rows={accHolders}
                        headCells={headCells}
                        checkboxless={true}
                        page={page}
                        hidePagination={true}
                        setSortBy={setSortBy}
                        setSortType={setSortType}
                    />
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={rowCounts}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => handleRowsPerPageChange(event, offset, setRowsPerPage, setPage, setOffset)}
                        ActionsComponent={TablePaginationActions}
                    />
                </>
            )}
        </div>
    );
}

export default ManageAccountHolders;