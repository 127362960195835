import React from "react";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { Box, InputAdornment, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {parseDate} from '../utils/DateUtils'

const useStyles = makeStyles((theme) => ({
    datePicker: {
        width: '100%',
        '& .MuiFormControl-root': {
            width: '100%',
            marginTop: 0,
            marginBottom: 0,
        },
        "& .Mui-focused": {
            color: "white"
        },
        "& .MuiInputBase-root": {
            '&::before': {
                content: 'none'
            },
            '&::after': {
                content: 'none'
            },
            width: '100%',
            height: theme.spacing(5),
            borderRadius: theme.spacing(2.5),
            boxSizing: 'border-box',
            padding: '8px 16px',
            background: '#000000',
            color: 'white',
            '& .MuiSvgIcon-root': {
                color: 'white'
            },
        }
    },
    sus: {
        '& .MuiButtonBase-root': {
            backgroundColor: 'transparent'
        },
        '& .MuiPickersStaticWrapper-staticWrapperRoot': {
            backgroundColor: 'transparent'
        }
    }
}))

export function BrandDatePicker({ label = 'Choose Date', selectedDate, setSelectedDate, maxDate, minDate }) {
    const styles = useStyles();
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    return (
        maxDate && minDate ?
            <div className={styles.datePicker}>
                {label ?
                    <Typography
                        component='div'
                        variant='subtitle2'
                    >
                        {label}
                    </Typography>
                    :
                    null
                }
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <ExpandMoreIcon />
                                </InputAdornment>
                            )
                        }}
                        minDate={parseDate(minDate)}
                        maxDate={parseDate(maxDate)}
                        disableToolbar
                        autoOk={true}
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        value={selectedDate}
                        onChange={handleDateChange}
                        minDateMessage={`Date should not be before ${parseDate(minDate)}`}
                        maxDateMessage={`Date should not be after ${parseDate(maxDate)}`}
                    />
                </MuiPickersUtilsProvider>
            </div >
            :
            maxDate ?
                <div className={styles.datePicker}>
                    {label ?
                        <Typography
                            component='div'
                            variant='subtitle2'
                        >
                            {label}
                        </Typography>
                        :
                        null
                    }
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <ExpandMoreIcon />
                                    </InputAdornment>
                                )
                            }}
                            maxDate={parseDate(maxDate)}
                            disableToolbar
                            autoOk={true}
                            variant="inline"
                            format="yyyy-MM-dd"
                            margin="normal"
                            value={selectedDate}
                            onChange={handleDateChange}
                            maxDateMessage={`Date should not be after ${parseDate(maxDate)}`}
                        />
                    </MuiPickersUtilsProvider>
                </div>
                :
                minDate ?
                    <div className={styles.datePicker}>
                        {label ?
                            <Typography
                                component='div'
                                variant='subtitle2'
                            >
                                {label}
                            </Typography>
                            :
                            null
                        }
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <ExpandMoreIcon />
                                        </InputAdornment>
                                    )
                                }}
                                minDate={parseDate(minDate)}
                                disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="yyyy-MM-dd"
                                margin="normal"
                                value={selectedDate}
                                onChange={handleDateChange}
                                minDateMessage={`Date should not be before ${parseDate(minDate)}`}
                            />
                        </MuiPickersUtilsProvider>
                    </div >
                    :
                    <>
                        <div className={styles.datePicker}>
                            {label ?
                                <Typography
                                    component='div'
                                    variant='subtitle2'
                                >
                                    {label}
                                </Typography>
                                :
                                null
                            }
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <ExpandMoreIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                    disableToolbar
                                    autoOk={true}
                                    variant="inline"
                                    format="yyyy-MM-dd"
                                    margin="normal"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                />
                            </MuiPickersUtilsProvider>
                        </div >
                    </>
    );
}