import React, { createContext } from 'react';

const navType = sessionStorage.getItem('sessionNavType');

export const initialStoreState = {
  user: null,
  hideNavigation: false,
  disableRootPadding: false,
  navType: navType ? navType : 'Music',
  hidePageProps: false,
  initialLoad: false,
  uploadReportFileName: '',
  findSongsInDb: true,
  portfolioItems: {
    countTotal: 0,
    items: []
  },
  alertSettings: {
    open: false,
    severity: 'success',
    text: '',
    autoHideDuration: 2000
  },
  songsMap: {},
  partialSongsMap: {},
  displayName: '',
  changedIndex: [],
  originalIndexes: [],
  quarterPickerStartDate: 19701,
  selectedAccountholderPortfolio: 0,
  selectedSongPortfolioHeadFilter:''
};

export const StoreContext = createContext(initialStoreState);
export const useStoreContext = () => React.useContext(StoreContext);
