import React from "react";
import { Button } from "@material-ui/core";
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ButtonSize } from "../../constants/buttonConstants";

const primaryCommonStyles = (theme) => ({
    backgroundColor: theme.palette.primary.main,
    height: theme.spacing(5),
    borderRadius: theme.spacing(5),
    '&:hover': {
        backgroundColor: theme.palette.primary.hover,
    },
    "&:disabled": {
        backgroundColor: theme.palette.primary.lightGray,
        cursor: 'not-allowed',
        "& .MuiButton-label": {
            color: 'black'
        }
    }
});

const secondaryCommonStyles = (theme) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(5),

    height: theme.spacing(5),
    '&:hover': {
        color: theme.palette.primary.hover,
        backgroundColor: theme.palette.background.default,
    },
    "&:disabled": {
        backgroundColor: theme.palette.primary.lightGray,
        cursor: 'not-allowed',
        "& .MuiButton-label": {
            color: theme.palette.text.primary
        }
    }
})

const deleteCommonStyles = (theme) => ({
    backgroundColor: theme.palette.primary.delete,
    height: theme.spacing(5),
    borderRadius: theme.spacing(5),
    '&:hover': {
        backgroundColor: theme.palette.primary.deleteHover,
    },
    "&:disabled": {
        backgroundColor: theme.palette.primary.lightGray,
        "& .MuiButton-label": {
            color: 'black'
        }
    }
})

const defaultSizeCommonStyles = () => ({
    padding: '20px 10px',
    font: 'normal normal normal 18px Roboto',
    minWidth: '93px',
})

const smallSizeCommonStyles = () => ({
    padding: '16px 6px',
    font: 'normal normal normal 16px Roboto',
    minWidth: '93px',
})

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.hover,
        },
        borderRadius: theme.spacing(5),
        "&:disabled": {
            backgroundColor: theme.palette.primary.lightGray,
            "& .MuiButton-label": {
                color: 'black'
            }
        }
    },
    secondaryRoot: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.hover,
            fontWeight: 'bold'
        },
        borderRadius: theme.spacing(5),
        "&:disabled": {
            backgroundColor: theme.palette.primary.disabled,
            "& .MuiButton-label": {
                color: theme.palette.text.primary
            }
        }
    },
    deleteButton: {
        backgroundColor: theme.palette.primary.alert,
        color: theme.palette.text.primary,
        '&:hover': {
            backgroundColor: theme.palette.primary.alertHover,
        },
        borderRadius: theme.spacing(5),
        "&:disabled": {
            backgroundColor: theme.palette.primary.lightGray,
            "& .MuiButton-label": {
                color: 'black'
            }
        }
    },

    primaryDefault: {
        ...primaryCommonStyles(theme),
        ...defaultSizeCommonStyles(),
    },
    primarySmall: {
        ...primaryCommonStyles(theme),
        ...smallSizeCommonStyles(),
    },
    secondaryDefault: {
        ...secondaryCommonStyles(theme),
        ...defaultSizeCommonStyles(),
    },
    secondarySmall: {
        ...secondaryCommonStyles(theme),
        ...smallSizeCommonStyles(),
    },
    deleteDefault: {
        ...deleteCommonStyles(theme),
        ...defaultSizeCommonStyles(),
    },
    deleteSmall: {
        ...deleteCommonStyles(theme),
        ...smallSizeCommonStyles(),
    }

}));

export function TempBrandButton({ size = ButtonSize.DEFAULT, capitalize = false, ...params }) {
    const styles = useStyles();
    const capitalizeStyle = capitalize ? { textTransform: 'uppercase' } : { textTransform: 'none' };

    return (
        <Button
            variant="contained"
            classes={{ root: size === ButtonSize.DEFAULT ? styles.primaryDefault : styles.primarySmall }}
            style={capitalizeStyle}
            {...params}
        />
    );
}

export function TempSecondaryBrandButton({ size = ButtonSize.DEFAULT, capitalize = false, ...params }) {
    const styles = useStyles();
    const capitalizeStyle = capitalize ? { textTransform: 'uppercase' } : { textTransform: 'none' };

    return (
        <Button
            variant="contained"
            classes={{ root: size === ButtonSize.DEFAULT ? styles.secondaryDefault : styles.secondarySmall }}
            style={capitalizeStyle}
            {...params}
        />
    );
}

export function TempBrandDeleteButton({ size = ButtonSize.DEFAULT, capitalize = false, ...params }) {
    const styles = useStyles();
    const capitalizeStyle = capitalize ? { textTransform: 'uppercase' } : { textTransform: 'none' };

    return (
        <Button
            variant="contained"
            classes={{ root: size === ButtonSize.DEFAULT ? styles.deleteDefault : styles.deleteSmall }}
            style={capitalizeStyle}
            {...params}
        />
    );
}


export function BrandButton(params) {
    const styles = useStyles();
    return (
        <Button variant="contained" classes={{ root: styles.root }} {...params} />
    );
}

export function SecondaryBrandButton(params) {
    const styles = useStyles();
    return (
        <Button variant="contained" classes={{ root: styles.secondaryRoot }} {...params} />
    );
}

export function BrandDeleteButton(params) {
    const styles = useStyles();
    return (
        <Button variant="contained" classes={{ root: styles.deleteButton }} {...params} />
    );
}

export function BrandInformationalButton(params) {
    const styles = useStyles();
    return (
        <Button variant="outlined" style={{
            backgroundColor: '#1B1B1B',
            color: 'white', alignItems: 'center', textAlign: 'center', borderColor: '#fff'
        }} classes={{ root: styles.root }} {...params} />
    );
}

export function BrandNeutralButton(params) {
    const styles = useStyles();
    return (
        <Button variant="outlined" style={{ background: '#343434 0% 0% no-repeat padding-box', borderColor: '1px solid #F4F4F48A', color: '#62E162', alignItems: 'center', textAlign: 'center' }} classes={{ root: styles.root }} {...params} />
    );
}

export function DeleteRowButton(params) {
    return (
        <Button variant="contained" {...params} />
    );
}
