import React, { useEffect, useState } from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { BrandMultiSelect } from '../CoreComponents/BrandSelect'
import { BrandInformationalButton, TempBrandDeleteButton, TempSecondaryBrandButton } from '../CoreComponents/BrandButton'
import { makeStyles } from '@material-ui/core/styles';
import { BrandCanvasTable } from '../CoreComponents/BrandCanvasTable.js';
import { excelDateToJSDate } from '../utils/DateUtils'
import { BrandModal } from '../CoreComponents/BrandModal'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { BrandLoaderSpinner } from '../CoreComponents/BrandLoader';
import { useStoreContext } from '../../store/Store';
import { postData } from '../utils/FetchUtils';
import { ButtonSize } from '../../constants/buttonConstants.js';

const useStyles = makeStyles((theme) => ({
    colHeader: {
        background: '#626262 0% 0% no-repeat padding-box',
        opacity: 1,
        height: '44px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: '1px solid #F4F4F48A',
    },
    mappingSelect: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '16px',
        color: 'white',
        fontWeight: 'bold',
        width: '250px',
        minWidth: '250px',
        maxWidth: '250px',
        textAlign: 'center',
        backgroundColor: '#1E2124',
        border: '1px solid #F4F4F48A',
        '&:not(last-child)': {
            borderRight: 'none',
        },
        '& .unfilledMandatory': {
            backgroundColor: '#f58270',
            padding: '0.3em 0.6em'
        },
        '& .unfilled': {
            backgroundColor: theme.palette.primary.attention,
            color: 'black',
            padding: '0.3em 0.6em'
        }
    },
    mappingTop: {
        display: 'flex',
        marginTop: '1em'
    },
    mappingTable: {
        width: '100%',
        overflow: 'auto',
        overflowX: 'auto',
        '& .x-spreadsheet-bottombar': {
            display: 'none'
        }
    },
    serverMaps: {
        marginLeft: '2%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
    },
    serverMappingInfo: {
        display: 'flex',
        flexDirection: 'row',
        overflow: 'auto',
        width: 750
    },
    fileField: {
        display: 'flex',
        flexDirection: 'column',
        paddingRight: '2%',
        height: '100%',
        borderRight: '1px solid white',
        '& p': {
            cursor: 'pointer',
        },
        '& .selected': {
            background: '#252525',
            borderRadius: '5px',
            fontWeight: 'bold'
        }
    },
    title: {
        fontSize: '30px',
    },
    table: {
        overflow: 'hidden',
        width: '100%',
        '& .x-spreadsheet': {
            marginTop: '-50px',
            marginLeft: '-59px',
        },
        '& .x-spreadsheet-overlayer': {
            display: 'none !important'
        },
        '& .x-spreadsheet-scrollbar': {
            display: 'none !important',
            '& .vertical': {
                display: 'none !important'
            }
        }
    },
    vertical: {
        height: '85vh',
        display: 'inline-block',
        border: '1px solid #393939',
        marginLeft: '8px',
        marginRight: '16px'
    },
    loader: {
        display: 'flex',
        justifyContent: "center",
        marginTop: theme.spacing(2)
    },
    saveMappingsModalBtnContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '10px',
        marginTop: '30px'
    },

}));

const REVENUE_REPLACE_REGEX = /[^\d.eE-]/g;
const WRONG_CSV_FORMAT_REGEX = /="([^"]*)"/;
const REVENUE_MATCH_REGEX = /[-+]?(?:\d+\.?\d*|\.\d+)(?:[eE][-+]?\d+)?/g;

const GetServerMappingInfoText = ({ dataMappings }) => {
    const styles = useStyles();
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectedInfoMapKey, setSelectedInfoMapKey] = useState('');
    const [selectedMappingOptions, setSelectedMappingOptions] = useState([]);
    const [mappingNames] = useState(Object.keys(dataMappings));

    useEffect(() => {
        if (!selectedInfoMapKey) {
            setSelectedMappingOptions(['Please select a column!'])
        } else if (dataMappings[selectedInfoMapKey]) {
            setSelectedMappingOptions(dataMappings[selectedInfoMapKey].alternativeNames);
        }
    }, [selectedInfoMapKey]);

    return (
        <div className={styles.serverMappingInfo}>
            <div>
                <h3>Required Columns</h3>
                <List>
                    {mappingNames.map((x, indx) =>
                        <ListItem
                            button
                            key={x + '-' + indx}
                            selected={selectedIndex === indx}
                            onClick={() => {
                                setSelectedIndex(indx);
                                setSelectedInfoMapKey(x);
                            }}
                        >
                            <ListItemText primary={x} />
                        </ListItem>
                    )}
                </List>
            </div>
            <hr className={styles.vertical} />
            <div className={styles.serverMaps}>
                <h3>Automatic Mapping</h3>
                {selectedMappingOptions.map((x, indx) => <p key={x + '-' + indx}>{x}</p>)}
            </div>
        </div>
    )
};

export default function Mapping({
    reportingCompany,
    reportType,
    csvContentAndMappings,
    setCsvContentAndMappings,
    setMapDone,
    setValidationRows,
    dataMappings,
    mappingTemplate,
    mappingColumns,
    isProcessingFile,
    setIsProcessingFile,
    isMapClicked,
    setIsMapClicked,
    handleNext,
    isNewMappingsSaved,
    setIsNewMappingsSaved,
    didWentBack,
    validationRows,
    retryUpload,
    setDataMappings
}) {
    const styles = useStyles();
    const [state, setState] = useStoreContext();
    const [showMappingInfoModal, setShowMappingInfoModal] = useState(false);
    const [fileColumnNames, setFileColumnNames] = useState([]);
    const [autodetected, setAutodetected] = useState({});
    const [autoDetectedTrigger, setAutoDetectedTrigger] = useState(false);
    const [autoDetect, setAutoDetect] = useState(false);
    const [tableOptions, setTableOptions] = useState({
        mode: 'read',
        showToolbar: false,
        showContextmenu: false,
        autoFill: false,
        view: {
            height: () => 591,
            width: () => mappingTemplate.length * 250 + 210
        },
        row: {
            // len: csvContent.length,
            height: 50
        },
        col: {
            len: mappingTemplate.length,
            width: 250
        },
        style: {
            bgcolor: 'rgb(30,33,36)',
            color: '#CFCFCF',
            align: 'right',
            font: {
                name: 'Roboto',
                size: 11,
                bold: false,
                italic: false,
            }, border: {
                top: ['thin', '#1E2124'],
                bottom: ['thin', '#1E2124'],
                right: ['thin', '#929395'],
                left: ['thin', '#929395'],
            }
        }
    });
    const [tableRows, setTableRows] = useState([]);
    const [mandatoryColumnsCount] = useState(Object.values(dataMappings).filter(o => o && o.required).length);
    const [lastEditColumnIndex, setLastEditColumnIndex] = useState(-1);
    const [saveMappingModal, setSaveMappingModal] = useState(false);
    const [isAllAlternativeNamesEqual, setIsAllAlternativeNamesEqual] = useState(true);
    const [loaded, setLoaded] = useState(0)

    useEffect(() => {
        setLoaded(loaded + 1)
        if (loaded > 1) {
            setIsNewMappingsSaved(false);
        }
        setIsAllAlternativeNamesEqual(false)

    }, [tableOptions, csvContentAndMappings])

    useEffect(() => {
        const { csvContent } = csvContentAndMappings;

        if (csvContent.length === 0) {
            setIsProcessingFile(false);
            return;
        }

        const { manualMapping, mappingRow } = csvContentAndMappings;
        setFileColumnNames(Object.keys(mappingRow).map((name) => ({ name, id: name })));

        const fileMapping = {};
        const foundMappings = {};

        mappingTemplate.forEach((column) => {
            const manualMap = manualMapping[column];
            fileMapping[column] = [];

            if (manualMap) {
                fileMapping[column] = manualMap;
                updateColumnClass(column, 'unfilledMandatory', true);
                updateColumnClass(column, 'unfilled', true);
                
                if (dataMappings[column]?.required) {
                    foundMappings[column] = true;
                }
            } else if (!autoDetect) {
                let found = false;
                const { alternativeNames } = dataMappings[column];

                alternativeNames.some((potentialMapColumn) => {
                    potentialMapColumn = findMatchingKey(potentialMapColumn, mappingRow);

                    const map = mappingRow[potentialMapColumn];

                    if (map) {
                        fileMapping[column] = [potentialMapColumn];
                        updateManualMapping(column, potentialMapColumn, manualMapping);

                        if (dataMappings[column].required) {
                            foundMappings[column] = true;
                        }

                        found = true;
                        return true;
                    }

                    return false;
                });

                if (!found) {
                    if (dataMappings[column].required) {
                        updateColumnClass(column, 'unfilledMandatory', false);
                    } else {
                        updateColumnClass(column, 'unfilled', false);
                    }
                }
            } else {
                if (dataMappings[column].required) {
                    updateColumnClass(column, 'unfilledMandatory', false);
                } else {
                    updateColumnClass(column, 'unfilled', false);
                }
            }
        });

        const isMapDone = Object.keys(foundMappings).length === mandatoryColumnsCount;
        setMapDone(isMapDone);

        const rows = [];
        const releaseDateIndex = mappingTemplate.indexOf('Release Date');
        const revenueIndex = mappingTemplate.indexOf('Revenue');

        csvContent
            .filter((row) => !Object.values(row).every((x) => x === ''))
            .forEach((row, rowIndex) => {
                rows.push({ cells: {} });
        
                mappingTemplate.forEach((column, columnIndex) => {
                    const columnResult = fileMapping[column].reduce((result, mappingColumn, i) => {
                        const value = row[mappingColumn] || '';
                        return result + (i === 0 ? value : ' ' + value);
                    }, '');

                    if (columnResult.length > 0) {
                        let text = columnResult.replace(/[\uFFFD]/, ' ')?.trim();

                        if (columnIndex === releaseDateIndex) {
                            text = excelDateToJSDate(text, true);
                        }

                        const cellText = determineCellTextContent(text, columnIndex, revenueIndex);

                        rows[rowIndex].cells[columnIndex] = { text: cellText };
                    } else if (columnIndex === revenueIndex) {
                        rows[rowIndex].cells[columnIndex] = { text: '0.00' };
                    } else {
                        rows[rowIndex].cells[columnIndex] = { text: 'No Data' };
                    }
                });
            });

        for (const key in dataMappings) {
            const isColumnFilled = Object.keys(manualMapping).includes(dataMappings[key].name);

            dataMappings[key].alternativeNames = isColumnFilled 
                ? dataMappings[key].alternativeNames 
                : [];
        }

        setDataMappings(dataMappings);
        setTableRows(rows.slice(0, 10));

        if (!didWentBack) {
            setValidationRows(rows);
        } else {
            validationRows.forEach((row, i) => {
                Object.keys(row.cells).forEach((cellKey, j) => {
                    validationRows[i].cells[cellKey].text = rows[i].cells[j].text;
                });
            });

            setValidationRows(validationRows);
        }

        setAutodetected(manualMapping);
        setAutoDetectedTrigger(true);
        setAutoDetect(true);

        setTableOptions({
            ...tableOptions,
            row: {
                ...tableOptions.row,
                len: csvContent.length
            }
        });

        setIsProcessingFile(false);
    }, [csvContentAndMappings]);

    function determineCellTextContent(text, columnIndex, revenueIndex) {
        const wrongFormatMatch = text.match(WRONG_CSV_FORMAT_REGEX);

        if (wrongFormatMatch) {
            text = wrongFormatMatch[1];
        }

        if (columnIndex !== revenueIndex) {
            return text;
        }

        if (text.length === 0) {
            return text?.trim();
        }

        const isNegativeSpecial = text.charAt(0) === '(' && text.charAt(text.length - 1) === ')';
        const revenueMatch = text.replaceAll(REVENUE_REPLACE_REGEX, '').match(REVENUE_MATCH_REGEX);

        if (!revenueMatch || revenueMatch.length === 0) {
            return text?.trim();
        }

        const rev = parseFloat(revenueMatch[0]) * (isNegativeSpecial ? -1 : 1);
        return rev.toString();
    }

    function updateManualMapping(column, potentialMapColumn, manualMapping) {
        if (!manualMapping[column]) {
            manualMapping[column] = [];
        }

        if (!manualMapping[column].includes(potentialMapColumn)) {
            manualMapping[column] = [potentialMapColumn];
        }
    }

    function findMatchingKey(potentialMapColumn, mappingRow) {
        for (const key in mappingRow)  {
            if (key.toLocaleLowerCase() === potentialMapColumn.toLowerCase()) {
                return key;
            }
        }

        return potentialMapColumn;
    }

    function updateColumnClass(column, className, remove = false) {
        const element = document.getElementById(column);

        if (remove) {
            element?.classList.remove(className);
        } else {
            element?.classList.add(className);
        }
    }

    useEffect(() => {

        if (isMapClicked) {
            if (!isAllAlternativeNamesEqual && !isNewMappingsSaved) {
                setSaveMappingModal(true)
            } else {
                handleNext()
            }
        }
    }, [isMapClicked])

    const saveMappingHandler = (e) => {
        const btnValue = e.target.innerText
        setIsMapClicked(false)
        setSaveMappingModal(false)
        if (btnValue === "YES") {
            postData(process.env.REACT_APP_SERVER_HOST + '/api/admin/mappings', { autodetected, reportingCompany })
                .then(res => {
                    if (res.success) {
                        handleNext();
                        setIsNewMappingsSaved(true);
                    }
                })
                .catch(err => {
                    console.log(err.message);
                })
        } else if (btnValue === 'NO') {
            handleNext();
            setIsNewMappingsSaved(true);
        }
    }

    return (
        <React.Fragment>
            <BrandModal
                open={saveMappingModal}
                onClose={() => {
                    setSaveMappingModal(false)
                    setIsMapClicked(false)
                }}
            >
                <div>Do you want to save this mapping for future use?</div>
                <div className={styles.saveMappingsModalBtnContainer}>

                    <TempSecondaryBrandButton
                        size={ButtonSize.SMALL}
                        capitalize={true}
                        onClick={() => {setSaveMappingModal(false); setIsMapClicked(false);}}
                    >
                        no
                    </TempSecondaryBrandButton>
                    <TempBrandDeleteButton
                        size={ButtonSize.SMALL}
                        capitalize={true}
                        onClick={saveMappingHandler}
                    >
                        yes
                    </TempBrandDeleteButton>
                </div>
            </BrandModal>
            <BrandModal
                open={showMappingInfoModal}
                onClose={() => setShowMappingInfoModal(false)}
            >
                <GetServerMappingInfoText dataMappings={dataMappings} />
            </BrandModal>

            <BrandModal
                open={isProcessingFile}
                onClose={() => setIsProcessingFile(false)}
                notClosable={true}
            >
                <div className={styles.loader}>
                    <BrandLoaderSpinner />
                </div>
            </BrandModal>

            <div className={styles.title}>
                2. Mapping - {state.uploadReportFileName}
                <BrandInformationalButton onClick={() => setShowMappingInfoModal(true)}>
                    <InfoOutlinedIcon /> &nbsp;Info
                </BrandInformationalButton>
            </div>
            <p>
                Please select the columns, which correspond to the template columns<br></br>from the row above. You can select multiple columns.
            </p>
            <div className={styles.mappingTable}>
                <div className={styles.mappingTop}>
                    {mappingTemplate.map((column, index) => {
                        const value = column;
                        return (<div className={styles.mappingSelect} key={index}>
                            <div className={styles.colHeader} id={value}>
                                <span>{dataMappings[value].required ? `${value}*` : value}</span>
                            </div>
                            <BrandMultiSelect
                                label={value.toString()}
                                value={fileColumnNames}
                                isMapping={true}
                                autodetected={autodetected}
                                autoDetectedTrigger={autoDetectedTrigger}
                                templateCol={value}
                                mappingColumns={mappingColumns}
                                onChange={(args) => {
                                    if (!state.findSongsInDb) {
                                        if (!retryUpload) {
                                            setState(state => (state.findSongsInDb = true, { ...state }));
                                        }
                                    }
                                    const columnName = value.toString();
                                    setLastEditColumnIndex(index);
                                    if (args.length === 0) {
                                        setCsvContentAndMappings(csvContentAndMappings => (delete csvContentAndMappings.manualMapping[columnName], { ...csvContentAndMappings }));
                                    } else if (args.length > 1) {
                                        setCsvContentAndMappings(csvContentAndMappings => (csvContentAndMappings.manualMapping[columnName] = args, { ...csvContentAndMappings }));
                                    } else {
                                        let manualMap = [args[0]];
                                        setCsvContentAndMappings(_ => (csvContentAndMappings.manualMapping[columnName] = manualMap, { ...csvContentAndMappings }));
                                    }
                                }}
                                isFromCsvUpload={true}
                            />
                        </div>);
                    })}
                </div>
                <BrandCanvasTable
                    options={tableOptions}
                    rows={tableRows}
                    className={styles.table}
                    style={{ width: mappingTemplate.length * 250 }}
                />
            </div>
        </React.Fragment>
    );
}
