import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";
import { ChevronRight, ExpandMore } from "@material-ui/icons";
import { Fragment, useState } from "react";
import { BrandButton, SecondaryBrandButton, TempBrandButton, TempBrandDeleteButton } from "../CoreComponents/BrandButton";
import useAbortableFetch from "../../hooks/useAbortableFetch";
import { ButtonSize } from "../../constants/buttonConstants";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        background: theme.palette.background.darkNuanceVersion4,
        display: 'flex',
        flexDirection: 'row',
        padding: '17.5px 0px',
        marginBottom: '10px',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    infoContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '2px'
    },
    mainContentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '25px'
    },
    itemWraper: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '40px'
    },
    emptySpace: {
        height: '56px',
        width: '56px'
    },
    icon: {
        color: 'white',
        fontSize: '32px'
    },
    mergedSongsCount: {
        color: theme.palette.text.greenNuanceVersion2
    },
    actionsWrapper: {
        marginRight: '25px',
        display: 'flex',
        flexDirection: 'row',
        columnGap: '50px',
        alignItems: 'center'
    },
    leftSideContentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    buttonsWrapper: {
        width: '250px',
        display: 'flex',
        justifyContent: 'flex-end',
        columnGap: '25px',
        '& button': {
            width: '123px',
        }
    }
}));

const RecursiveSongView = ({ 
    object, 
    isOrphanNode = true, 
    toggleExpand, 
    expandedItems,
    setSelectedSong,
    setShowUnmergeModal,
    setShowMoveModal
}) => {
    const styles = useStyles();

    const handleUnmergeButtonClick = (song) => {
        setSelectedSong(song);
        setShowUnmergeModal(true);
    }

    const handleMoveButtonClick = (song) => {
        setSelectedSong(song);
        setShowMoveModal(true);
    }

    return (
        <Fragment>
            { object.map(({
                id,
                displayName,
                artist,
                mergedSongs,
                lastModifiedDate,
                parentId,
                parentTitle,
                parentArtist
            }) => (
                <Box className={styles.itemWraper} key={id}>
                    <Box className={styles.mainContainer}>
                        <Box className={styles.leftSideContentWrapper}>
                            { mergedSongs ? (
                                <IconButton onClick={() => toggleExpand(id)}>
                                    { expandedItems.includes(id) 
                                        ? <ExpandMore className={styles.icon} /> 
                                        : <ChevronRight className={styles.icon} /> 
                                    }
                                </IconButton>
                            ) : (
                                <Box className={styles.emptySpace} />
                            ) }
                            <Box className={styles.mainContentWrapper}>
                                <Box className={styles.infoContentWrapper}>
                                    <Typography variant='body2'>Song title</Typography>
                                    <Typography>{displayName}</Typography>
                                </Box>
                                <Box className={styles.infoContentWrapper}>
                                    <Typography variant='body2'>Artist</Typography>
                                    <Typography>{artist}</Typography>
                                </Box>
                                <Box className={styles.infoContentWrapper}>
                                    <Typography variant='body2'>Last modified date</Typography>
                                    <Typography>{lastModifiedDate}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={styles.actionsWrapper}>
                            { mergedSongs &&
                                <Typography className={styles.mergedSongsCount}>
                                    { mergedSongs.length === 1 
                                        ? `${mergedSongs.length} merged song` 
                                        : `${mergedSongs.length} merged songs` 
                                    }
                                </Typography> 
                            }
                            <Box className={styles.buttonsWrapper}>
                                <TempBrandButton 
                                    size={ButtonSize.SMALL}
                                    onClick={() => handleMoveButtonClick({ 
                                        id, 
                                        displayName, 
                                        artist, 
                                        parentId, 
                                        parentTitle, 
                                        parentArtist 
                                    })}
                                >
                                    Move
                                </TempBrandButton>
                                { !isOrphanNode && (
                                    <TempBrandDeleteButton 
                                        size={ButtonSize.SMALL}
                                        onClick={() => handleUnmergeButtonClick({ 
                                            id, 
                                            displayName, 
                                            artist, 
                                            parentId, 
                                            parentTitle, 
                                            parentArtist 
                                        })}
                                    >
                                        Unmerge    
                                    </TempBrandDeleteButton>
                                ) }
                            </Box>
                        </Box>
                    </Box>  

                    { expandedItems.includes(id) && mergedSongs && 
                        <RecursiveSongView 
                            object={mergedSongs} 
                            isOrphanNode={false} 
                            toggleExpand={toggleExpand} 
                            expandedItems={expandedItems} 
                            setSelectedSong={setSelectedSong}
                            setShowUnmergeModal={setShowUnmergeModal}
                            setShowMoveModal={setShowMoveModal}
                        /> 
                    }
                </Box>
            )) }
        </Fragment>
    )
}

export default RecursiveSongView;