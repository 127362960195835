import { Button, Popover, makeStyles } from "@material-ui/core";
import { ExpandMore, Search } from "@material-ui/icons";
import { useState, useRef } from "react";
import CustomAutocomplete from "./CustomAutocomplete";

const useStyles = makeStyles((theme) => ({
    button: {
        padding: '2.5px 20px',
        borderRadius: theme.spacing(2.5),
        border: '1px solid gray',
        width: '270px',
        textTransform: 'capitalize',
        maxWidth: '270px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&.Mui-disabled': {
            color: 'grey'
        }
    },
    buttonIcon: {
        marginLeft: 'auto',
        marginRight: '-10px'
    },
    searchIcon: {
        marginRight: '10px'
    }
}));

const SearchButton = ({
    textContent,
    searchQuery,
    setSearchQuery,
    options,
    setOptions,
    offset,
    callback,
    selectedOptions,
    setSelectedOptions,
    placeholder,
    allSelectedText,
    closeOutsidePopover,
    openOutsidePopover,
    disabled,
    showSearchIcon,
    isLoading,
    handleResetAutoCompletes,
    disableAccountHolderAutoComplete
}) => {
    const styles = useStyles(); 
    const [openPopover, setOpenPopover] = useState(false);
    const anchorEl = useRef(null);

    const handleClick = () => {
        setOpenPopover(true);
        closeOutsidePopover();
    }

    const handleClose = () => {
        setOpenPopover(false);
        setSearchQuery('')
    }

    return (
        <div ref={anchorEl}>
            <Button 
                className={styles.button} 
                onClick={handleClick} 
                disabled={disabled}
                onMouseEnter={openOutsidePopover}
                onMouseLeave={closeOutsidePopover}
            >
                { showSearchIcon && <Search className={styles.searchIcon} /> } 
                { textContent.length > 23 ? `${textContent.substring(0, 21)}...` : textContent}
                <ExpandMore fontSize='large' className={styles.buttonIcon} />
            </Button>
            <Popover
                open={openPopover}
                anchorEl={anchorEl.current}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <CustomAutocomplete
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    options={options}
                    setOptions={setOptions}
                    offsetRef={offset}
                    callback={callback}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    placeholder={placeholder}
                    allSelectedText={allSelectedText}
                    isLoading={isLoading}
                    handleResetAutoCompletes={handleResetAutoCompletes}
                    disableAccountHolderAutoComplete={disableAccountHolderAutoComplete}
                />
            </Popover>
        </div>
    )
};

export default SearchButton;